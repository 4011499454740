import * as Yup from 'yup'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'

import { checkIsEmpty, Lookup } from '../../../utils'
import { Form } from './uom.styled'
import { UomAPI } from '../../../api'
import { MasterModal, Input, Alerts, ModalDel, InputSelect } from '../../../components'
import LogAPI from '../../../api/master-data/log'
import { messageErrorFormikLog } from '../../../utils/message'

const UomModal = (props) => {
  const isEdit = props.edit
  const isDetail = props.detail
  const isDelete = props.delete
  const [multiClick, setMultClick] = useState(0)
  const [showAlert, setAlert] = useState({ visible: false, msg: '', success: false, type: '' })
  const [uomTypeList, setUomTypeList] = useState([])

  const _insertUom = async (data) => {
    try {
      const response = await UomAPI.InsertUom(data)
      if (response && response.result) {
        setAlert({
          msg: 'Add data has been successful',
          success: true,
          visible: true,
          type: 'insert',
        })
      } else {
        setAlert({
          msg: 'UOM data already exists',
          success: false,
          visible: true,
          type: 'insert',
        })
      }
    } catch (err) {
      console.log(err)
      return err
    }
  }

  const _updateUom = async (data) => {
    try {
      const response = await UomAPI.UpdateUom(data)
      if (response && response.result) {
        setAlert({
          msg: 'Update data has been successful',
          success: true,
          visible: true,
          type: 'update',
        })
      } else {
        setAlert({
          msg: 'Uom data already exists',
          success: false,
          visible: true,
          type: 'update',
        })
      }
    } catch (err) {
      console.log(err)
      return err
    }
  }

  const _deleteUom = async () => {
    try {
      const response = await UomAPI.DeleteUom(props.data.Id)
      if (response && response.result) {
        props.setDeleted(false)
        setAlert({
          msg: 'Delete data has been successful',
          success: true,
          visible: true,
          type: 'delete',
        })
      } else {
        setAlert({ msg: 'Cannot delete data', success: false, visible: true, type: 'delete' })
      }
    } catch (err) {
      console.log(err)
      return err
    }
  }

  const _close = () => {
    setMultClick(0)
    setAlert({ ...showAlert, visible: false })
    if (showAlert.success) {
      props.setModal(false)
      FormInput.resetForm()
    }
  }

  const _deleteModal = () => (
    <div>
      <ModalDel
        submitDelete={_deleteUom}
        deleteText='Are you sure to delete the data ?'
        toggleDialog={() => {
          FormInput.resetForm()
          props.setModal(false)
        }}
      />
    </div>
  )

  const _getUomType = async () => {
    try {
      const response = await UomAPI.GetListUomType()
      setUomTypeList(response.data.data)
    } catch (err) {
      return err
    }
  }

  useEffect(() => {
    _getUomType()
  }, [])

  const FormInput = useFormik({
    enableReinitialize: true,
    initialValues: {
      Id: isEdit || isDetail ? props.data.Id : 0,
      Uom_Type_Code: isEdit || isDetail ? props.data.Uom_Type_Code : '',
      Uom_Text: isEdit || isDetail ? props.data.Uom_Text : '',
      Is_Active: isEdit || isDetail ? props.data.Is_Active : true,
      Code: isEdit || isDetail ? props.data.Uom_Code : 'UOCXXXX',
    },
    validationSchema: Yup.object({
      Id: Yup.number(),
      Code: Yup.string(),
      Uom_Type_Code: Yup.string().required('Required*'),
      Uom_Text: Yup.string().required('Required*'),
      Is_Active: Yup.boolean(),
    }),
    onSubmit: async (values, form) => {
      setMultClick(multiClick + 1)
      if (checkIsEmpty(values.Uom_Text)) {
        form.setValues({
          ...values,
          Uom_Text: '',
        })
      } else {
        let data = {}
        Object.keys(values)
          .filter(
            (val) =>
              val == 'Uom_Type_Code' || val == 'Uom_Text' || val == 'Is_Active' || val == 'Id'
          )
          .forEach((vl) => {
            data[vl] = values[vl]
          })
        if (isEdit) {
          if (multiClick === 0) {
            _updateUom(data)
          }
        } else {
          if (multiClick === 0) {
            _insertUom(data)
          }
        }
      }
    },
  })
  const insertLog = async () => {
    const detail =
      Object.entries(FormInput.touched).length > 0
        ? messageErrorFormikLog(FormInput.errors)
        : Lookup.LOG_MESSAGE.NO_INPUT

    const dataInsert = {
      Timestamp: moment().toISOString(),
      Action: isEdit ? Lookup.LOG_ACTION.UPDATE : Lookup.LOG_ACTION.ADD,
      Module: Lookup.LOG_MODULE.MASTER_DATA,
      Submodule: Lookup.LOG_SUB_MODULE,
      Detail: detail,
      Logtype: Lookup.LOG_TYPE.SYSTEM_LOG,
      Errorlevel: Lookup.LOG_ERROR_LEVEL.ERROR,
      From: '',
      To: '',
      Refno: FormInput.values.Code,
    }

    if (
      (Object.entries(FormInput.touched).length === 0 && !isEdit) || //submit w/o touch field
      (Object.entries(FormInput.errors).length > 0 && multiClick === 0) // submit after touch field
    ) {
      const { data } = await LogAPI.PostDataLog(dataInsert)
      if (data && data.result) return data.result
    } else return false
  }

  return (
    <>
      {isDelete && _deleteModal()}
      {props.modal && (
        <MasterModal
          maxWidth='lg'
          width={35}
          add={!isDetail}
          title='Master Data'
          showModal={props.modal}
          handleClose={() => {
            FormInput.resetForm()
            props.setModal(false)
          }}
          subTitle={
            isEdit
              ? 'Edit Master Data UOM'
              : isDetail
                ? 'Detail Master Data UOM'
                : 'Add Master Data UOM'
          }
          handleSave={() => {
            insertLog()
            FormInput.handleSubmit()
          }}
        >
          <Form>
            <Input
              id='m_uom_code_txt'
              name='Code'
              form={FormInput}
              label='UOM Code'
              value={FormInput.values.Code}
              InputProps={{ readOnly: true }}
              inputProps={{
                tabIndex: 1,
              }}
            />
            <Input
              id='m_uom_txt'
              form={FormInput}
              name='Uom_Text'
              label='UOM'
              value={FormInput.values.Uom_Text}
              InputProps={{ readOnly: isDetail }}
              inputProps={{
                tabIndex: 3,
              }}
            />
            {(isEdit || isDetail) && (
              <Input
                id='m_uom_modby_txt'
                label='Modified By'
                InputProps={{ readOnly: true }}
                value={props.data.Modified_By}
              />
            )}
          </Form>
          <Form>
            <InputSelect
              id='m_uom_type_txt'
              label='UOM Type'
              name='Uom_Type_Code'
              options={uomTypeList}
              optionText='Value'
              optionValueKey='Code'
              readOnly={isDetail}
              form={FormInput}
              tabIndex={2}
            />
            {(isEdit || isDetail) && (
              <InputSelect
                id='m_uom_status_txt'
                label='Status'
                name='Is_Active'
                options={[
                  { text: 'Enable', value: true },
                  { text: 'Disable', value: false },
                ]}
                optionText='text'
                optionValueKey='value'
                readOnly={!isEdit}
                form={FormInput}
                tabIndex={4}
              />
            )}
            {(isEdit || isDetail) && (
              <Input
                id='m_uom_modon_txt'
                label='Modified On'
                InputProps={{ readOnly: true }}
                value={moment(props.data.Modified_Date).format('DD/MM/YYYY HH:mm:ss')}
              />
            )}
          </Form>
        </MasterModal>
      )}
      <Alerts
        type={showAlert.type}
        msg={showAlert.msg}
        close={() => _close()}
        open={showAlert.visible}
        success={showAlert.success}
      />
    </>
  )
}

export default UomModal
