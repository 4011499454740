import styled from 'styled-components'
import { Colors } from '../../../styles'

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.height}px;
`

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: center;
  justify-content: space-around;
`

export const ActionCell = styled.div`
  cursor: pointer;
`

export const Form = styled.div`
  flex: 1;
  display: flex;
  padding: 10px 30px;
  flex-direction: column;
`

export const CodeCell = styled.a`
  cursor: pointer;

  &:hover {
    color: ${Colors.softBlue} !important;
  }
`
