import * as Yup from 'yup'
import moment from 'moment'

import { useState } from 'react'
import { useFormik } from 'formik'

import { Form } from './client.styled'
import { ClientAPI } from '../../../api'
import { MasterModal, Input, Alerts, ModalDel, InputSelect } from '../../../components'

const ClientModal = (props) => {
  const isEdit = props.edit
  const isDetail = props.detail
  const isDelete = props.delete
  const [multiClick, setMultClick] = useState(0)
  const [showAlert, setAlert] = useState({ visible: false, msg: '', success: false, type: '' })

  const _insertClient = async (data) => {
    try {
      const response = await ClientAPI.Insert(data)
      if (response && response.result) {
        FormInput.resetForm()
        props.setModal(false)
        setAlert({
          msg: 'Add data has been successful',
          success: true,
          visible: true,
          type: 'insert',
        })
      } else {
        setAlert({
          msg: response.mesage,
          success: false,
          visible: true,
          type: 'insert',
        })
      }
    } catch (err) {
      console.log(err)
      return err
    }
    // */
  }
  const _updateClient = async (data) => {
    try {
      const response = await ClientAPI.Update(data)
      if (response && response.result) {
        props.setModal(false)
        setAlert({
          msg: 'Update data has been successful',
          success: true,
          visible: true,
          type: 'update',
        })
      } else {
        setAlert({ msg: response.msg, success: false, visible: true, type: 'update' })
      }
    } catch (err) {
      console.log(err)
      return err
    }
  }

  const _deleteClient = async () => {
    try {
      const response = await ClientAPI.Delete(props.data.Id)
      if (response && response.result) {
        props.setModal(false)
        setAlert({
          msg: 'Delete data has been successful',
          success: true,
          visible: true,
          type: 'delete',
        })
      } else {
        setAlert({ msg: 'Cannot delete data', success: false, visible: true, type: 'delete' })
      }
    } catch (err) {
      console.log(err)
      return err
    }
  }

  const _deleteModal = () => (
    <div>
      <ModalDel
        submitDelete={_deleteClient}
        deleteText='Are you sure want to delete'
        toggleDialog={() => props.setModal(false)}
      />
    </div>
  )

  const FormInput = useFormik({
    enableReinitialize: true,
    initialValues: {
      ID: isEdit || isDetail ? props.data.Id : 0,
      Company_Code: isEdit || isDetail ? props.data.Company_Code : '',
      Division_Client: isEdit || isDetail ? props.data.Division_Client : '',
      Address: isEdit || isDetail ? props.data.Address : '',
      Fax: isEdit || isDetail ? props.data.Fax : '',
      Sales_Org: isEdit || isDetail ? props.data.Sales_Org : '',
      SAP_Customer_No: isEdit || isDetail ? props.data.SAP_Customer_No : '',
      Phone: isEdit || isDetail ? props.data.Phone : '',
      Email: isEdit || isDetail ? props.data.Email : '',
      Dist_Channel: isEdit || isDetail ? props.data.Dist_Channel : '',
      Customer_Name: isEdit || isDetail ? props.data.Customer_Name : '',
      Mobile_Phone: isEdit || isDetail ? props.data.Mobile_Phone : '',
      Is_Active: isEdit || isDetail ? props.data.Is_Active : true,
      Modified_By: isEdit || isDetail ? props.data.Modified_By : '',
    },
    validationSchema: Yup.object({
      Address: Yup.string().required('*Address*').trim(),
      Fax: Yup.string(),
      Phone: Yup.number(),
      Email: Yup.string().email(),
      Dist_Channel: Yup.string(),
      Company_Code: Yup.string(),
      Division_Client: Yup.string(),
      Sales_Org: Yup.string(),
      Customer_Name: Yup.string().required('*Customer Name*').trim(),
      Mobile_Phone: Yup.number(),
      Is_Active: Yup.bool().required('*Status*'),
      SAP_Customer_No: Yup.string(),
    }),
    onSubmit: async (values) => {
      setMultClick(multiClick + 1)
      let data = {}
      Object.keys(values)
        .filter((val) => val == 'Code' || val == 'Is_Active' || val == 'Id')
        .forEach((vl) => {
          data[vl] = values[vl]
        })
      if (isEdit) {
        if (multiClick === 0) {
          _updateClient(values)
        }
      } else {
        if (multiClick === 0) {
          _insertClient(values, FormInput)
        }
      }
    },
  })

  return (
    <>
      <Alerts
        type={showAlert.type}
        msg={showAlert.msg}
        close={() => {
          setMultClick(0)
          setAlert({ ...showAlert, visible: false })
        }}
        open={showAlert.visible}
        success={showAlert.success}
      />
      {isDelete ? (
        _deleteModal()
      ) : (
        <MasterModal
          width={35}
          maxWidth='lg'
          add={!isDetail}
          title='Master Data'
          showModal={props.modal}
          handleClose={() => {
            FormInput.resetForm()
            FormInput.setSubmitting(false)
            props.setModal(false)
          }}
          subTitle={
            isEdit
              ? 'Edit Master Data Client'
              : isDetail
                ? 'Detail Master Data Client'
                : 'Add Master Data Client'
          }
          handleSave={FormInput.handleSubmit}
        >
          {/* left */}
          <Form>
            <Input
              id='m_clnt_code_txt'
              name='Company_Code'
              form={FormInput}
              label='Company Code'
              value={FormInput.values.Company_Code}
              onChange={FormInput.handleChange}
              InputProps={{ readOnly: isDetail }}
            />
            <Input
              id='m_clnt_desc_txt'
              form={FormInput}
              name='Division_Client'
              label='Division'
              onChange={FormInput.handleChange}
              InputProps={{ readOnly: isDetail }}
              value={FormInput.values.Division_Client}
            />

            <Input
              id='m_clnt_address_txt'
              name='Address'
              form={FormInput}
              label='Address'
              value={FormInput.values.Address}
              onChange={FormInput.handleChange}
              InputProps={{ readOnly: isDetail }}
            />
            <Input
              id='m_clnt_fax_txt'
              form={FormInput}
              name='Fax'
              label='Fax'
              onChange={FormInput.handleChange}
              InputProps={{ readOnly: isDetail }}
              value={FormInput.values.Fax}
            />

            {(isEdit || isDetail) && (
              <Input
                id='m_clnt_modifiedon_txt'
                name='Modified_Date'
                label='Modified On'
                value={moment(props.data.Modified_Date).format('DD/MM/YYYY HH:mm:ss')}
                InputProps={{ readOnly: true }}
              />
            )}
          </Form>

          {/* middle */}

          <Form>
            <Input
              id='m_clnt_salorg_txt'
              name='Sales_Org'
              form={FormInput}
              label='Sales Org '
              value={FormInput.values.Sales_Org}
              onChange={FormInput.handleChange}
              InputProps={{ readOnly: isDetail }}
            />
            <Input
              id='m_clnt_custno_txt'
              form={FormInput}
              name='SAP_Customer_No'
              label='SAP Customer No'
              onChange={FormInput.handleChange}
              InputProps={{ readOnly: isDetail }}
              value={FormInput.values.SAP_Customer_No}
            />

            <Input
              id='m_clnt_phone_txt'
              name='Phone'
              form={FormInput}
              label='Phone'
              value={FormInput.values.Phone}
              onChange={FormInput.handleChange}
              InputProps={{ readOnly: isDetail }}
            />
            <Input
              id='m_clnt_email_txt'
              form={FormInput}
              name='Email'
              label='Email'
              onChange={FormInput.handleChange}
              InputProps={{ readOnly: isDetail }}
              value={FormInput.values.Email}
            />
            {(isEdit || isDetail) && (
              <Input
                id='m_clnt_modifiedby_txt'
                name='Modified_By'
                label='Modified By'
                value={props.data.Modified_By}
                InputProps={{ readOnly: true }}
              />
            )}
          </Form>

          {/* right */}

          <Form>
            <Input
              id='m_clnt_channel_txt'
              name='Dist_Channel'
              form={FormInput}
              label='Dist. Channel'
              value={FormInput.values.Dist_Channel}
              onChange={FormInput.handleChange}
              InputProps={{ readOnly: isDetail }}
            />
            <Input
              id='client_name_txt'
              form={FormInput}
              name='Customer_Name'
              label='Customer Name'
              onChange={FormInput.handleChange}
              InputProps={{ readOnly: isDetail }}
              value={FormInput.values.Customer_Name}
            />

            <Input
              id='m_clnt_mobphone_txt'
              name='Mobile_Phone'
              form={FormInput}
              label='Mobile Phone'
              value={FormInput.values.Mobile_Phone}
              onChange={FormInput.handleChange}
              InputProps={{ readOnly: isDetail }}
            />
            {(isEdit || isDetail) && (
              <InputSelect
                id='m_clnt_status_txt'
                label='Status'
                name='Is_Active'
                options={[
                  { text: 'Enable', value: true },
                  { text: 'Disable', value: false },
                ]}
                optionText='text'
                optionValueKey='value'
                readOnly={!isEdit}
                form={FormInput}
              />
            )}
          </Form>
        </MasterModal>
      )}
    </>
  )
}

export default ClientModal
