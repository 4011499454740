import * as Yup from 'yup'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import CloseIcon from '@material-ui/icons/Close'
// import MenuItem from '@material-ui/core/MenuItem'

import { prefix, checkIsEmpty, setToBase64, Lookup } from '../../../utils'
import { LogAPI, VesselAPI } from '../../../api'
import { Images } from '../../../assets'
import { GET_ATTACHMENT_FILE } from '../../../redux/actions'
import {
  Input as InputField,
  Alerts,
  MasterModal,
  ModalDel,
  InputSelect,
} from '../../../components'

import {
  Form,
  ImageIconFile,
  CloseButtonIcon,
  FileContainer,
  FileContainerImage,
  ImageIconImage,
} from './vessel.styled'
import { messageErrorFormikLog } from '../../../utils/message'
const ModalInput = (props) => {
  const isEdit = props.edit
  const isDetail = props.detail
  const [multiClick, setMultClick] = useState(0)
  const { dataVesselCategory, dataDetail, dataVesselType, dataVesselSize } = useSelector(
    (state) => state.vesselReducer
  )
  const statusVessel = [
    { Id: '1', status: true, label: 'Enable' },
    { Id: '2', status: false, label: 'Disable' },
  ]
  const registeredVessel = [
    { Id: '1', status: true, label: 'Registered' },
    { Id: '2', status: false, label: 'Not Registered' },
  ]

  let vesselSizeName = ' '
  let vesselType = ''
  let vesselSizeCode = ''
  const [fileCode, setFileCode] = useState('')
  const [imageCode, setImageCode] = useState('')
  const [imageFile, setImageFile] = useState('')
  const [fileNameForm, setFileNameForm] = useState('')
  const [imageNameForm, setImageNameForm] = useState('')
  const [fileContent, setFileContent] = useState('')
  const [toggleDelete, setToggleDelete] = useState(false)
  const [vesselTypeCode, setVesselTypeCode] = useState('')
  const [clearIconFile, setClearIconFile] = useState(false)
  const [clearIconImage, setClearIconImage] = useState(false)
  const [toggleDeleteImage, setToggleDeleteImage] = useState(false)
  const [fileDownload, setFileDownload] = useState({ filename: '', dataBase64: '' })
  const [imageDownload, setImageDownload] = useState({ filename: '', dataBase64: '' })

  const downloadFileFromServer = async () => {
    try {
      const response = await VesselAPI.GetAttachmentFile(props.fileName)
      const baseFile64 = response.data.data
      const prefixBase64 = prefix(props.fileName)
      const dataBase64 = prefixBase64 + baseFile64
      setFileDownload({ filename: props.fileName, dataBase64: dataBase64 })
      document.getElementById('download').click()
    } catch (err) {
      return err
    }
  }
  const downloadImageFromServer = async () => {
    try {
      const response = await VesselAPI.GetAttachmentFile(props.imageName)
      const baseFile64 = response.data.data
      const prefixBase64 = prefix(props.imageName)
      const dataBase64 = prefixBase64 + baseFile64
      setImageDownload({ filename: props.imageName, dataBase64: dataBase64 })
      document.getElementById('download2').click()
    } catch (err) {
      return err
    }
  }

  const previewFile = async() => {
    if (
      props.dataItem.Filename.includes('.pdf')
    ) {
      let win = window.open()
      win.document.write(`<iframe 
    style="
    width:100%;
    height:100%;
    "
    src="${fileContent}" />`)
    }
  }

  const previewImage = async() => {
    if (
      props.dataItem.Image_File_Name.includes('.jpg') ||
      props.dataItem.Image_File_Name.includes('.png')
    ) {
      let win = window.open()
      win.document.write(`<iframe 
    style="
    width:100%;
    height:100%;
    "
    src="${imageFile}" />`)
    }
  }
  const clearFile = () => {
    props.setShowFile(false)
    setClearIconFile(true)
    setToggleDelete(!toggleDelete)
  }
  const clearImage = () => {
    props.setShowImage(false)
    setClearIconImage(true)
    setToggleDeleteImage(!toggleDeleteImage)
  }

  const iconChooser = (fileName) => {
    if (fileName != '') {
      let imageFile = {}
      const extension = fileName.split('.')[fileName.split('.').length - 1]
      let fileNameShow = fileName.slice(0, 25) + '...' + extension
      if (extension == 'xlsx' || extension == 'xls') {
        imageFile = Images.filesIconXls
      } else if (extension == 'doc' || extension == 'docx') {
        imageFile = Images.filesIconDoc
      } else if (extension == 'pdf') {
        imageFile = Images.filesIconPdf
      } else {
        imageFile = Images.filesIconImg
      }

      return (
        <>
          {isDetail && <label style={{ fontSize: '16px' }}>Ship Particular File</label>}
          <FileContainer type={isDetail ? 'detail' : 'edit'}>
            {!isDetail && (<CloseButtonIcon>
              {!isDetail && (
                <span
                  onClick={() => {
                    setToggleDelete(!toggleDelete)
                  }}
                >
                  {' '}
                  <CloseIcon />{' '}
                </span>
              )}
            </CloseButtonIcon>)}
            <Tooltip title={<label style={{ fontSize: '16px' }}>{props.fileName}</label>}>
              <ImageIconFile
                type={isDetail ? 'detail' : 'edit'}
                src={imageFile}
                onClick={ () => {
                  isDetail && props.dataItem.Filename.includes('.pdf')
                    ? previewFile() : downloadFileFromServer()}
                }
              />
            </Tooltip>
            <br></br>
            <div>{fileNameShow}</div>
          </FileContainer>
        </>
      )
    } else {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {isDetail && <label style={{ fontSize: '16px' }}>Ship Particular File</label>}
          <br></br>
          <label style={{ fontSize: '14px', color: 'red' }}>Not Available</label>
        </div>
      )
    }
  }

  useEffect(() => {
    if (props.imageName) {
      getImageIcon(props.imageName)
    }
    if (props.fileName) {
      getFileContent(props.fileName)
    }
  }, [props.modal])

  const getImageIcon = async (imageName) => {
    setToBase64(imageName)
      .then(function (response) {
        return response
      })
      .then(function (data) {
        setImageFile(data)
      })
  }

  const getFileContent = async (fileName) => {
    setToBase64(fileName)
      .then(function (response) {
        return response
      })
      .then(function (data) {
        setFileContent(data)
      })
  }

  const imageChooser = (imageName) => {
    if (imageName != '') {
      // const img = Promise.result(imageFile2)
      // Promise.then((result) => console.log(result)).catch((err) => console.log(err))

      const extension = imageName.split('.')[imageName.split('.').length - 1]
      let imageNameShow = imageName.slice(0, 25) + '...' + extension

      return (
        <>
          {isDetail && <label style={{ fontSize: '16px' }}>Vessel Image</label>}

          <FileContainerImage type={isDetail ? 'detail' : 'edit'}>
            {!isDetail && (<CloseButtonIcon style={{ width: '18vh' }}>
              {!isDetail && (
                <span
                  style={{ float: 'right' }}
                  onClick={() => {
                    setToggleDeleteImage(!toggleDeleteImage)
                  }}
                >
                  {' '}
                  <CloseIcon />{' '}
                </span>
              )}
            </CloseButtonIcon>)}
            <Tooltip title={<label style={{ fontSize: '16px' }}>{props.imageName}</label>}>
              <ImageIconImage
                type={isDetail ? 'detail' : 'edit'}
                src={imageFile}
                onClick={ () => {
                  isDetail &&
                  ( props.dataItem.Image_File_Name.includes('.jpg') ||
                  props.dataItem.Image_File_Name.includes('.png') )
                    ? previewImage() : downloadImageFromServer()
                }
                } />
            </Tooltip>
            <br></br>
            <div>{imageNameShow}</div>
          </FileContainerImage>
        </>
      )
    } else {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {isDetail && <label style={{ fontSize: '16px' }}>Vessel Image</label>}
          <br></br>
          <label style={{ fontSize: '14px', color: 'red' }}>Not Available</label>
        </div>
      )
    }
  }

  const uploadingFileToServer = async (formData, callback) => {
    console.time()
    try {
      const response = await VesselAPI.UploadAttachmentFile(formData)
      callback(response)

      await dispatchEvent({
        type: GET_ATTACHMENT_FILE,
        payload: { dataAttachment: response.data.data },
      })
    } catch (err) {
      return err
    }
  }
  const insertLogAttachment = async (detail) => {
    const dataInsert = {
      Timestamp: moment().toISOString(),
      Action: Lookup.LOG_ACTION.UPLOAD,
      Module: Lookup.LOG_MODULE.MASTER_DATA,
      Submodule: Lookup.LOG_SUB_MODULE.SERVICE_REQUEST + '[' + Lookup.LOG_ACTION.UPLOAD + ']',
      Detail: detail,
      Logtype: Lookup.LOG_TYPE.SYSTEM_LOG,
      Errorlevel: Lookup.LOG_ERROR_LEVEL.ERROR,
      From: '',
      To: '',
      Refno: formSubmit.values.Code,
    }

    return await LogAPI.PostDataLog(dataInsert)
  }
  const fileHandler = (e) => {
    if (e.target.files[0].size > props.fileSize * 1024 * 1024) {
      props.setAlert({
        msg: `Failed. File size exceed ${props.fileSize} Mb`,
        success: false,
        visible: true,
      })
      document.getElementById('formInput').reset()
      insertLogAttachment(`Failed. File size exceed ${props.fileSize} Mb`)
    } else {
      const formdataUpload = new FormData()
      formdataUpload.append('file', e.target.files[0])

      uploadingFileToServer(formdataUpload, (callback) => {
        setFileCode(callback.data.data.Code)
        setFileNameForm(callback.data.data.FileName)
        // submit ur code here, use callback properly
      })
    }
  }
  const imageHandler = (e) => {
    if (e.target.files[0].size > props.fileSize * 1024 * 1024) {
      props.setAlert({
        msg: `Failed. File size exceed ${props.fileSize} Mb`,
        success: false,
        visible: true,
      })
      document.getElementById('formInputImage').reset()
    } else {
      const formdataUpload = new FormData()
      formdataUpload.append('file', e.target.files[0])

      uploadingFileToServer(formdataUpload, (callback) => {
        setImageCode(callback.data.data.Code)
        setImageNameForm(callback.data.data.FileName)
        // submit ur code here, use callback properly
      })
    }
  }
  const formSubmit = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...dataDetail,
      Length_Overall: dataDetail.Length_Overall,
      Vessel_Size: dataDetail.Vessel_Size !== '' ? dataDetail.Vessel_Size : ' ',
    },
    validationSchema: Yup.object({
      ID: Yup.number(),
      Code: Yup.string(),
      Vessel_Category_Code: Yup.string().required('Vessel Category cannot be empty'),
      Vessel_Type_Code: Yup.string().required('Vessel Type cannot be empty'),
      Vessel_Size_Code: Yup.string(),
      Vessel_Size: Yup.string(),
      Vessel_Name: Yup.string().required('Vessel Name cannot be empty'),
      Device_Id: Yup.string(),
      Imo_No: Yup.string().required('IMO No cannot be empty'),
      Length_Overall: Yup.number().min(1, 'Length cannot be 0').required('Length cannot be empty'),
      Contract_No: Yup.string(),
      Dead_Weight: Yup.number(),
      Depth_Moulded: Yup.number(),
      Breadth_Moulded: Yup.number(),
      Draft: Yup.number(),
      Gross_Tonnage: Yup.number(),
      Net_Tonnage: Yup.number(),
      Build_Year: Yup.number(),
      Speed: Yup.number(),
      Call_Sign: Yup.string().required('Call Sign cannot be empty'),
      Flag: Yup.string(),
      Mmsi: Yup.number().required('MMSI cannot be empty'),
      Is_Active: Yup.bool(),
      Ship_Particular_File: Yup.string(),
      Image_File: Yup.string(),
      Filename: Yup.string(),
    }),
    onSubmit: async (values, form) => {
      if (values.Vessel_Type_Code == props.bargeCode) {
        vesselType = 'Barge'
      } else if (values.Vessel_Type_Code == props.lctCode) {
        vesselType = 'LCT'
      } else if (values.Vessel_Type_Code == props.tsvCode) {
        vesselType = 'TSV'
      } else if (values.Vessel_Type_Code == props.tankerCode) {
        vesselType = 'Tanker'
      } else if (values.Vessel_Type_Code == props.rigCode) {
        vesselType = 'Rig'
      } else if (
        values.Vessel_Type_Code != props.bargeCode ||
        values.Vessel_Type_Code != props.lctCode ||
        values.Vessel_Type_Code != props.tsvCode ||
        values.Vessel_Type_Code != props.rigCode ||
        values.Vessel_Type_Code != props.tankerCode
      ) {
        vesselType = 'General/Cargo'
      }

      for (var i = 0; i < dataVesselSize.length; i++) {
        const data = dataVesselSize[i].Value.split('|')
        if (
          vesselType === data[0] &&
          parseFloat(data[2]) <= parseFloat(values.Length_Overall) &&
          parseFloat(values.Length_Overall) <= parseFloat(data[3])
        ) {
          vesselSizeName = data[1]
          vesselSizeCode = dataVesselSize[i].Code
        }
      }

      if (checkIsEmpty(values.Vessel_Name)) {
        form.setValues({
          ...values,
          Vessel_Name: '',
        })
      } else if (checkIsEmpty(values.Call_Sign)) {
        form.setValues({
          ...values,
          Call_Sign: '',
        })
      } else if (checkIsEmpty(values.Imo_No)) {
        form.setValues({
          ...values,
          Imo_No: '',
        })
      } else {
        setMultClick(multiClick + 1)
        let formData = {}
        Object.keys(values)
          .filter(
            (v) =>
              v == 'Id' ||
              v == 'Code' ||
              v == 'Vessel_Type_Code' ||
              v == 'Vessel_Category_Code' ||
              v == 'Vessel_Size_Code' ||
              v == 'Vessel_Size' ||
              v == 'Vessel_Name' ||
              v == 'Device_Id' ||
              v == 'Contract_No' ||
              v == 'Imo_No' ||
              v == 'Length_Overall' ||
              v == 'Dead_Weight' ||
              v == 'Depth_Moulded' ||
              v == 'Breadth_Moulded' ||
              v == 'Draft' ||
              v == 'Gross_Tonnage' ||
              v == 'Net_Tonnage' ||
              v == 'Build_Year' ||
              v == 'Speed' ||
              v == 'Call_Sign' ||
              v == 'Flag' ||
              v == 'Mmsi' ||
              v == 'Is_Active' ||
              v == 'Ship_Particular_File' ||
              v == 'Image_File' ||
              v == 'Filename'
          )
          .forEach((v) => {
            if (isEdit) {
              if (!clearIconFile && fileNameForm == '') {
                if (v == 'Ship_Particular_File') {
                  values[v] = props.shipCode
                  formData[v] = values[v]
                } else if (v == 'Image_File') {
                  if (imageNameForm == '') {
                    if (!clearIconImage) {
                      values[v] = props.imageCode
                      formData[v] = values[v]
                    } else {
                      values[v] = ''
                      formData[v] = values[v]
                    }
                  } else {
                    values[v] = imageCode
                    formData[v] = values[v]
                  }
                } else if (v == 'Vessel_Size_Code') {
                  values[v] = vesselSizeCode
                  formData[v] = values[v]
                } else if (v == 'Vessel_Size') {
                  values[v] = vesselSizeName
                  formData[v] = values[v]
                } else if (
                  v == 'Breadth_Moulded' ||
                  v == 'Length_Overall' ||
                  v == 'Dead_Weight' ||
                  v == 'Depth_Moulded' ||
                  v == 'Draft' ||
                  v == 'Gross_Tonnage' ||
                  v == 'Net_Tonnage' ||
                  v == 'Speed' ||
                  v == 'Mmsi' ||
                  v == 'Build_Year'
                ) {
                  formData[v] = parseFloat(values[v])
                } else if (v == 'Contract_No') {
                  if (props.isRegular) {
                    formData[v] = values[v]
                  } else {
                    formData[v] = ''
                  }
                } else {
                  formData[v] = values[v]
                }
              } else if (clearIconFile && fileNameForm == '') {
                if (v == 'Ship_Particular_File') {
                  values[v] = ''
                  formData[v] = values[v]
                } else if (v == 'Image_File') {
                  if (imageNameForm == '') {
                    if (!clearIconImage) {
                      values[v] = props.imageCode
                      formData[v] = values[v]
                    } else {
                      values[v] = ''
                      formData[v] = values[v]
                    }
                  } else {
                    values[v] = imageCode
                    formData[v] = values[v]
                  }
                } else if (v == 'Filename') {
                  values[v] = ''
                  formData[v] = values[v]
                } else if (v == 'Vessel_Size_Code') {
                  values[v] = vesselSizeCode
                  formData[v] = values[v]
                } else if (v == 'Vessel_Size') {
                  values[v] = vesselSizeName
                  formData[v] = values[v]
                } else if (
                  v == 'Breadth_Moulded' ||
                  v == 'Length_Overall' ||
                  v == 'Dead_Weight' ||
                  v == 'Depth_Moulded' ||
                  v == 'Draft' ||
                  v == 'Gross_Tonnage' ||
                  v == 'Net_Tonnage' ||
                  v == 'Speed' ||
                  v == 'Mmsi' ||
                  v == 'Build_Year'
                ) {
                  formData[v] = parseFloat(values[v])
                } else if (v == 'Contract_No') {
                  if (props.isRegular) {
                    formData[v] = values[v]
                  } else {
                    formData[v] = ''
                  }
                } else {
                  formData[v] = values[v]
                }
              } else {
                if (v == 'Ship_Particular_File') {
                  values[v] = fileCode
                  formData[v] = values[v]
                } else if (v == 'Image_File') {
                  if (imageNameForm == '') {
                    if (!clearIconImage) {
                      values[v] = props.imageCode
                      formData[v] = values[v]
                    } else {
                      values[v] = ''
                      formData[v] = values[v]
                    }
                  } else {
                    values[v] = imageCode
                    formData[v] = values[v]
                  }
                } else if (v == 'Filename') {
                  values[v] = fileNameForm
                  formData[v] = values[v]
                } else if (v == 'Vessel_Size_Code') {
                  values[v] = vesselSizeCode
                  formData[v] = values[v]
                } else if (v == 'Vessel_Size') {
                  values[v] = vesselSizeName
                  formData[v] = values[v]
                } else if (
                  v == 'Breadth_Moulded' ||
                  v == 'Length_Overall' ||
                  v == 'Dead_Weight' ||
                  v == 'Depth_Moulded' ||
                  v == 'Draft' ||
                  v == 'Gross_Tonnage' ||
                  v == 'Net_Tonnage' ||
                  v == 'Speed' ||
                  v == 'Mmsi' ||
                  v == 'Build_Year'
                ) {
                  formData[v] = parseFloat(values[v])
                } else if (v == 'Contract_No') {
                  if (props.isRegular) {
                    formData[v] = values[v]
                  } else {
                    formData[v] = ''
                  }
                } else {
                  formData[v] = values[v]
                }
              }
            } else {
              if (v == 'Ship_Particular_File') {
                values[v] = fileCode
                formData[v] = values[v]
              } else if (v == 'Vessel_Size_Code') {
                values[v] = vesselSizeCode
                formData[v] = values[v]
              } else if (v == 'Vessel_Size') {
                values[v] = vesselSizeName
                formData[v] = values[v]
              } else if (
                v == 'Breadth_Moulded' ||
                v == 'Length_Overall' ||
                v == 'Dead_Weight' ||
                v == 'Depth_Moulded' ||
                v == 'Draft' ||
                v == 'Gross_Tonnage' ||
                v == 'Net_Tonnage' ||
                v == 'Speed' ||
                v == 'Mmsi' ||
                v == 'Build_Year'
              ) {
                formData[v] = parseFloat(values[v])
              } else if (v == 'Contract_No') {
                if (props.isRegular) {
                  formData[v] = values[v]
                } else {
                  formData[v] = ''
                }
              } else {
                formData[v] = values[v]
              }
            }
          })
        if (isEdit) {
          try {
            if (multiClick === 0) {
              const response = await VesselAPI.EditDataVessel(formData)
              if (response.data && response.data.result) {
                form.resetForm()
                props.getData(props.dataState)
                props.setModal(false)
                props.setAlert({
                  msg: 'Update data has been successful',
                  success: true,
                  visible: true,
                  type: 'update',
                })
              } else {
                props.setAlert({
                  msg: 'Vessel data already exist',
                  success: false,
                  visible: true,
                  type: 'update',
                })
              }
            }
          } catch (err) {
            return err
          }
        } else {
          try {
            if (multiClick === 0) {
              const response = await VesselAPI.PostDataVessel(formData)
              if (response.data && response.data.result) {
                form.resetForm()
                props.getData(props.dataState)
                props.setModal(false)
                props.setAlert({
                  msg: 'Add data has been successful',
                  success: true,
                  visible: true,
                  type: 'insert',
                })
              } else {
                props.setAlert({
                  msg: 'Vessel data already exist',
                  success: false,
                  visible: true,
                  type: 'insert',
                })
              }
            }
          } catch (err) {
            return err
          }
        }
        setClearIconFile(false)
        setClearIconImage(false)
        setFileNameForm('')
        setFileCode('')
        setImageCode('')
        setImageNameForm('')
      }
    },
  })

  const insertLog = async () => {
    const detail =
      Object.entries(formSubmit.touched).length > 0
        ? messageErrorFormikLog(formSubmit.errors)
        : 'Lookup.LOG_MESSAGE.NO_INPUT fields are not filled yet'
    const dataInsert = {
      Timestamp: moment().toISOString(),
      Action: isEdit ? Lookup.LOG_ACTION.UPDATE : Lookup.LOG_ACTION.ADD,
      Module: Lookup.LOG_MODULE.MASTER_DATA,
      Submodule:Lookup.LOG_SUB_MODULE.MASTER_VESSEL,
      Detail: detail,
      Logtype: Lookup.LOG_TYPE.SYSTEM_LOG,
      Errorlevel: Lookup.LOG_ERROR_LEVEL.ERROR,
      From: '',
      To: '',
      Refno: formSubmit.values.Code,
    }

    if (
      (Object.entries(formSubmit.touched).length === 0 && !isEdit) || //submit w/o touch field
      (Object.entries(formSubmit.errors).length > 0 && multiClick === 0) // submit after touch field
    ) {
      const { data } = await LogAPI.PostDataLog(dataInsert)
      if (data && data.result) return data.result
    } else return false
  }

  useEffect(() => {
    changeRegular()
  }, [formSubmit.values.Vessel_Category_Code])

  useEffect(() => {
    changeVesselType()
  }, [formSubmit.values.Vessel_Type_Code])

  const changeRegular = () => {
    if (formSubmit.values.Vessel_Category_Code === props.regularCode) {
      props.setIsRegular(true)
    } else {
      props.setIsRegular(false)
      // formSubmit.setFieldValue('Contract_No', '')
    }
    // formSubmit.setFieldValue('Vessel_Category_Code', _getVesCategoryValue(formSubmit.values.Vessel_Category_Code))
  }

  const changeVesselType = () => {
    setVesselTypeCode(formSubmit.values.Vessel_Type_Code)
    const value = formSubmit.values.Vessel_Type_Code
    if (value == props.bargeCode) {
      vesselType = 'Barge'
    } else if (value == props.lctCode) {
      vesselType = 'LCT'
    } else if (value == props.tsvCode) {
      vesselType = 'TSV'
    } else if (value == props.rigCode) {
      vesselType = 'Rig'
    } else if (value == props.tankerCode) {
      vesselType = 'Tanker'
    } else if (
      value != props.bargeCode ||
      value != props.lctCode ||
      value != props.tsvCode ||
      value != props.rigCode ||
      value != props.tankerCode
    ) {
      vesselType = 'General/Cargo'
    }
    for (var i = 0; i < dataVesselSize.length; i++) {
      const data = dataVesselSize[i].Value.split('|')
      if (
        vesselType == data[0] &&
        data[2] <= parseFloat(formSubmit.values.Length_Overall) &&
        parseFloat(formSubmit.values.Length_Overall) <= data[3]
      ) {
        if (parseFloat(formSubmit.values.Length_Overall) === 0) {
          vesselSizeName = ' '
        } else {
          vesselSizeName = data[1]
          vesselSizeCode = dataVesselSize[i].Code
        }
      }
    }
    formSubmit.setFieldValue('Length_Overall', formSubmit.values.Length_Overall)
    formSubmit.setFieldValue('Vessel_Size', vesselSizeName)
  }

  const changeLengthOverall = (event) => {
    if (vesselTypeCode == props.bargeCode) {
      vesselType = 'Barge'
    } else if (vesselTypeCode == props.lctCode) {
      vesselType = 'LCT'
    } else if (vesselTypeCode == props.tsvCode) {
      vesselType = 'TSV'
    } else if (vesselTypeCode == props.tankerCode) {
      vesselType = 'Tanker'
    } else if (vesselTypeCode == props.rigCode) {
      vesselType = 'Rig'
    } else if (
      vesselTypeCode != props.bargeCode ||
      vesselTypeCode != props.lctCode ||
      vesselTypeCode != props.tsvCode ||
      vesselTypeCode != props.tankerCode ||
      vesselTypeCode != props.rigCode ||
      vesselTypeCode != ''
    ) {
      vesselType = 'General/Cargo'
    }
    if (vesselTypeCode !== '') {
      for (var i = 0; i < dataVesselSize.length; i++) {
        const data = dataVesselSize[i].Value.split('|')
        if (
          vesselType == data[0] &&
          data[2] <= parseFloat(event.target.value) &&
          parseFloat(event.target.value) <= data[3]
        ) {
          if (parseFloat(event.target.value) == 0) {
            vesselSizeName = ' '
          } else {
            vesselSizeName = data[1]
            vesselSizeCode = dataVesselSize[i].Code
          }
        }
      }
    }
    formSubmit.setFieldValue('Length_Overall', event.target.value)
    if (vesselTypeCode !== '') {
      formSubmit.setFieldValue('Vessel_Size', vesselSizeName)
    }
  }
  const rightIndexConst = props.isRegular ? 3 : 0
  return (
    <div>
      <MasterModal
        maxWidth='lg'
        width={70}
        add={!isDetail}
        title='Master Data'
        showModal={props.modal}
        disableBackdropClick
        handleClose={() => {
          formSubmit.resetForm()
          formSubmit.setSubmitting(false)
          setFileCode('')
          setFileNameForm('')
          props.setModal()
          vesselSizeName = ''
          setFileContent('')
          setImageFile('')
        }}
        subTitle={
          isEdit
            ? 'Edit Master Data Vessel'
            : isDetail
              ? 'Detail Master Data Vessel'
              : 'Add Master Data Vessel'
        }
        handleSave={() => {
          insertLog()
          formSubmit.handleSubmit()
        }}
      >
        <Form>
          <InputField
            form={formSubmit}
            name='Code'
            label='Vessel Code'
            InputProps={{
              readOnly: true,
            }}
            id='m_vessel_form_code_tf'
            placeholder='VSCXXXX'
            inputProps={{
              tabIndex: 1,
            }}
          />
          <InputField
            form={formSubmit}
            InputProps={{
              readOnly: isDetail,
            }}
            name='Vessel_Name'
            label='Vessel Name'
            id='vessel_name_txt'
            inputProps={{
              tabIndex: 4,
            }}
          />
          <InputField
            form={formSubmit}
            type='number'
            InputProps={{
              readOnly: isDetail,
            }}
            name='Length_Overall'
            label='Length Overall (Meters)'
            id='m_vessel_form_lo_tf'
            onChange={changeLengthOverall}
            inputProps={{
              tabIndex: 7,
            }}
          />
          <InputField
            form={formSubmit}
            type='number'
            InputProps={{
              readOnly: isDetail,
            }}
            name='Breadth_Moulded'
            label='Breadth Moulded (Meters)'
            id='m_vessel_form_bm_tf'
            onChange={(event) => formSubmit.setFieldValue('Breadth_Moulded', event.target.value)}
            inputProps={{
              tabIndex: 10,
            }}
          />
          <InputField
            form={formSubmit}
            type='number'
            InputProps={{
              readOnly: isDetail,
            }}
            name='Build_Year'
            label='Build Year (Year)'
            id='m_vessel_form_by_tf'
            onChange={(event) => formSubmit.setFieldValue('Build_Year', event.target.value)}
            inputProps={{
              tabIndex: 13,
            }}
          />
          <InputField
            form={formSubmit}
            InputProps={{
              readOnly: isDetail,
            }}
            name='Call_Sign'
            label='Call Sign'
            id='m_vessel_form_cs_tf'
            inputProps={{
              tabIndex: 16,
            }}
          />
          <InputField
            form={formSubmit}
            type='number'
            InputProps={{
              readOnly: isDetail,
            }}
            name='Draft'
            label='Draft (Meters)'
            id='m_vessel_form_draft_tf'
            onChange={(event) => formSubmit.setFieldValue('Draft', event.target.value)}
            inputProps={{
              tabIndex: 19,
            }}
          />
          {(isDetail || isEdit) && (
            <InputSelect
              id='m_vessel_form_IsActive_tf'
              label='Status`'
              name='Is_Active'
              options={statusVessel}
              optionText='label'
              optionValueKey='status'
              readOnly={!isEdit}
              form={formSubmit}
              tabIndex={22}
            />
          )}
          {(isDetail || isEdit) && (
            <InputSelect
              id='m_vessel_form_IsRegistered_tf'
              label='Registered in Marine Traffic'
              name='Is_Registered'
              options={registeredVessel}
              optionText='label'
              optionValueKey='status'
              readOnly={true}
              form={formSubmit}
            />
          )}
        </Form>
        <Form>
          <InputSelect
            id='m_vessel_form_vstype_tf'
            label='Vessel Type'
            name='Vessel_Type_Code'
            options={dataVesselType}
            optionText='Vessel_Type'
            optionValueKey='Code'
            readOnly={isDetail}
            form={formSubmit}
            onChange={changeVesselType}
            tabIndex={2}
          />
          <InputField
            form={formSubmit}
            InputProps={{
              readOnly: isDetail,
            }}
            name='Device_Id'
            label='Device ID'
            id='m_vessel_form_devID_tf'
            inputProps={{
              tabIndex: 5,
            }}
          />
          <InputField
            form={formSubmit}
            type='number'
            InputProps={{
              readOnly: isDetail,
            }}
            name='Dead_Weight'
            label='Dead Weight (Tons)'
            id='m_vessel_form_DW_tf'
            onChange={(event) => formSubmit.setFieldValue('Dead_Weight', event.target.value)}
            inputProps={{
              tabIndex: 8,
            }}
          />
          <InputField
            form={formSubmit}
            type='number'
            InputProps={{
              readOnly: isDetail,
            }}
            name='Gross_Tonnage'
            label='Gross Tonnage (Tons)'
            id='m_vessel_form_GT_tf'
            onChange={(event) => formSubmit.setFieldValue('Gross_Tonnage', event.target.value)}
            inputProps={{
              tabIndex: 11,
            }}
          />
          <InputField
            form={formSubmit}
            InputProps={{
              readOnly: isDetail,
            }}
            name='Flag'
            label='Flag'
            id='m_vessel_form_flag_tf'
            inputProps={{
              tabIndex: 14,
            }}
          />
          <InputField
            form={formSubmit}
            type='number'
            InputProps={{
              readOnly: isDetail,
            }}
            name='Mmsi'
            label='MMSI'
            id='m_vessel_form_mmsi_tf'
            onChange={(event) => formSubmit.setFieldValue('Mmsi', event.target.value)}
            inputProps={{
              tabIndex: 17,
            }}
          />
          <div hidden={true}>
            <InputField
              form={formSubmit}
              InputProps={{
                readOnly: isDetail,
              }}
              name='Ship_Particular_File'
              id='shp'
            />
          </div>

          <form id='formInput'>
            <div
              style={{
                marginTop: '-5px',
                marginBottom: '5px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <label style={{ fontSize: '10px' }} hidden={isDetail}>
                Ship Particular File
              </label>
              <input
                hidden={isDetail}
                accept=' .pdf, .doc, .docx, .xls, .xlsx'
                disabled={isDetail}
                type='file'
                name='Ship_Particular_File'
                id='m_vessel_form_shp_tf'
                onChange={(event) => {
                  fileHandler(event)
                }}
                tabIndex={20}
              />
            </div>
            {props.showFile && iconChooser(props.fileName)}
          </form>
          <a
            href={fileDownload.dataBase64}
            download={fileDownload.filename}
            id='download'
            style={{ display: 'none' }}
          >
            {' '}
            download{' '}
          </a>
          <form id='formInputImage'>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label style={{ fontSize: '10px' }} hidden={isDetail}>
                Vessel Image
              </label>
              <input
                hidden={isDetail}
                accept=' .jpg, .png'
                disabled={isDetail}
                type='file'
                name='Image_File'
                id='m_vessel_form_image_file_tf'
                onChange={(event) => {
                  imageHandler(event)
                }}
                tabIndex={23}
              />
            </div>
            {props.showImage && imageChooser(props.imageName)}
          </form>
          <a
            href={imageDownload.dataBase64}
            download={imageDownload.filename}
            id='download2'
            style={{ display: 'none' }}
          >
            {' '}
            download{' '}
          </a>
        </Form>
        <Form>
          <InputSelect
            id='m_vessel_form_vscategory_tf'
            label='Vessel Category`'
            name='Vessel_Category_Code'
            options={dataVesselCategory}
            optionText='Value'
            optionValueKey='Code'
            readOnly={isDetail}
            form={formSubmit}
            tabIndex={3}
          />
          {props.isRegular && (
            <InputField
              form={formSubmit}
              InputProps={{
                readOnly: isDetail,
              }}
              name='Contract_No'
              label='Contract No'
              id='m_vessel_form_ctrct_tf'
              inputProps={{
                tabIndex: 6,
              }}
            />
          )}
          <InputField
            form={formSubmit}
            InputProps={{
              readOnly: isDetail,
            }}
            name='Imo_No'
            label='IMO NO'
            id='m_vessel_form_imono_tf'
            inputProps={{
              tabIndex: rightIndexConst + 6,
            }}
          />
          <InputField
            form={formSubmit}
            type='number'
            InputProps={{
              readOnly: isDetail,
            }}
            name='Depth_Moulded'
            label='Depth Moulded (Meters)'
            id='m_vessel_form_DepMou_tf'
            onChange={(event) => formSubmit.setFieldValue('Depth_Moulded', event.target.value)}
            inputProps={{
              tabIndex: rightIndexConst + 9,
            }}
          />
          <InputField
            form={formSubmit}
            type='number'
            InputProps={{
              readOnly: isDetail,
            }}
            name='Net_Tonnage'
            label='Net Tonnage (Tons)'
            id='m_vessel_form_NetTon_tf'
            onChange={(event) => formSubmit.setFieldValue('Net_Tonnage', event.target.value)}
            inputProps={{
              tabIndex: rightIndexConst + 12,
            }}
          />
          <InputField
            form={formSubmit}
            type='number'
            InputProps={{
              readOnly: isDetail,
            }}
            name='Speed'
            label='Speed (Knots)'
            id='m_vessel_form_speed_tf'
            onChange={(event) => formSubmit.setFieldValue('Speed', event.target.value)}
            inputProps={{
              tabIndex: rightIndexConst + 15,
            }}
          />
          <InputField
            form={formSubmit}
            inputProps={{
              readOnly: true,
              tabIndex: rightIndexConst + 17,
            }}
            label='Vessel Size`'
            id='m_vessel_form_vesselsize_tf'
            name='Vessel_Size'
          />
          <div style={{ display: 'none' }}>
            <InputField
              form={formSubmit}
              inputProps={{
                readOnly: true,
              }}
              label='Vessel Size`'
              id='m_vessel_form_vesselsizecode_tf'
              name='Vessel_Size_Code'
            />
          </div>
          {(isDetail || isEdit) && (
            <InputField
              form={formSubmit}
              id='m_vessel_form_modby_tf'
              name='Modified_By'
              label='Modified By'
              InputProps={{ readOnly: true }}
            />
          )}
          {isDetail || isEdit ? (
            <InputField
              // form={formSubmit}
              name='Modified_Date'
              label='Modified On'
              InputProps={{ readOnly: true }}
              value={
                dataDetail && dataDetail.Modified_Date
                  ? moment(dataDetail.Modified_Date).format('DD/MM/YYYY HH:mm:ss')
                  : ''
              }
            />
          ) : null}
        </Form>
      </MasterModal>
      <Alerts
        type={props.showAlert.type}
        open={props.showAlert.visible}
        close={() => {
          setMultClick(0)
          props.setAlert({ ...props.showAlert, visible: false })
        }}
        msg={props.showAlert.msg}
        success={props.showAlert.success}
      />
      {toggleDelete && (
        <ModalDel
          deleteText='Are you sure to delete the file ?'
          toggleDialog={() => setToggleDelete(!toggleDelete)}
          submitDelete={clearFile}
        />
      )}
      {toggleDeleteImage && (
        <ModalDel
          deleteText='Are you sure to delete the image ?'
          toggleDialog={() => setToggleDeleteImage(!toggleDeleteImage)}
          submitDelete={clearImage}
        />
      )}
    </div>
  )
}
export default ModalInput
