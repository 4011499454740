import { useSelector } from 'react-redux'
import DeleteIcon from '@material-ui/icons/Delete'
import React, { useState, useEffect } from 'react'
import CreateIcon from '@material-ui/icons/Create'
import getConfig from 'next/config'

import GridColumn from './column.json'
import { ApprovalApi } from '../../../api'
import ModalInput from './approval-modal.container'
import { GenerateFilterTerm } from '../../../utils'
import { Card, CodeCell, ModalDel, Alerts, MasterGrid, MasterHeader } from '../../../components'

import { RowIcon, ActionDiv } from './approval.styled'
import { WrapperContent } from '../../../styles/global.styled'

const Approval = () => {
  const appSize = useSelector((state) => state.appReducer)
  const usersLoginReducer = useSelector((state) => state.usersLoginReducer)

  const [state, setState] = useState({
    get: [],
  })
  const [pending, setPending] = useState(false)
  const [type, setType] = useState([])
  const [level, setLevel] = useState([])
  const [flag, setFlag] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [detail, setDetail] = useState(false)
  const [location, setLocation] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [flagStatus, setflagStatus] = useState(false)
  const [deleteData, setDeleteData] = useState({ Id: 0 })
  const [toggleDelete, setToggleDelete] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)

  const { publicRuntimeConfig } = getConfig()
  const approvalRole = useSelector((state) =>
    state.usersLoginReducer.roleMapping.find((e) => e.activeName.includes('master-data/approval'))
  )
  const [showAlert, setAlert] = React.useState({
    visible: false,
    msg: '',
    success: false,
    type: '',
  })
  const [editData, setEditData] = useState({
    Id: 0,
    Modified_By: '',
    Is_Active: true,
    Approval_Level: '',
    Approval_Type_Code: '',
    Approval_Level_Code: '',
    Modified_Date: '',
    Approval_Code: 'APCXXXX',
  })

  const handleClickOpen = () => {
    setEditData({
      Id: null,
      Modified_By: '',
      Is_Active: true,
      Approval_Level: '',
      Approval_Type_Code: '',
      Approval_Level_Code: '',
      Modified_Date: '',
      Approval_Code: 'APCXXXX',
    })
    getLevel()
    getType()
    getLocation()
    setShowModal(true)
  }

  const [dataCount, setDataCount] = useState(0)
  const [dataState, setDataState] = useState({ skip: 0, take: 20, filter: [], filterString: '[]' })

  const handleClose = async () => {
    setDetail(false)
    await setFlag(false)
    await setShowModal(false)
    await setflagStatus(false)
  }

  const onPageChange = (event) => {
    setDataState({
      ...dataState,
      skip: event.page.skip,
      take: event.page.take,
    })

    fetchData({
      skip: event.page.skip,
      take: event.page.take,
      filterString: dataState.filterString,
    })
  }

  const onDataStateChange = ({ data }) => {
    setDataState({
      ...dataState,
      ...data,
      filter: data.filter,
      filterString: GenerateFilterTerm(data.filter, GridColumn),
    })
  }

  useEffect(() => {
    const delay = setTimeout(() => {
      if(!firstLoad) {
        fetchData({ ...dataState,
          filterString: GenerateFilterTerm(dataState.filter, GridColumn) })
      }
    }, publicRuntimeConfig.FILTER_TIMEOUT)
    return () => clearTimeout(delay)
  }, [dataState.filter])

  const submitDelete = async () => {
    try {
      const response = await ApprovalApi.DelDataApproval(deleteData.Id)
      if (response.data && response.data.result) {
        fetchData(dataState)
        setAlert({
          msg: 'Delete data has been successful',
          success: true,
          visible: true,
          type: 'delete',
        })
      } else {
        setAlert({
          msg: 'Cannot delete data',
          success: false,
          visible: true,
          type: 'delete',
        })
      }
    } catch (err) {
      console.log(err)
      setAlert({
        msg: 'Cannot delete data',
        success: false,
        visible: true,
        type: 'delete',
      })
      return err
    }
    setToggleDelete(!toggleDelete)
  }

  const detailCell = (props) => {
    return (
      <CodeCell
        id='m-detail-btn'
        onClick={async () => {
          setEditData(props.dataItem)
          getLocation()
          getLevel()
          getType()
          setFlag(true)
          setDetail(!detail)
          setflagStatus(true)
          setShowModal(true)
        }}
      >
        {props.dataItem.Approval_Code}
      </CodeCell>
    )
  }

  const actionCell = ({ dataItem, colSpan }) => (
    <td>
      <RowIcon colSpan={colSpan}>
        <ActionDiv
          hidden={!approvalRole.allowEdit}
          id={`m-edit-btn-${dataItem.Approval_Code}`}
          onClick={() => {
            setIsEdit(true)
            setflagStatus(true)
            setEditData(dataItem)
            getLocation()
            getType()
            getLevel()
            setShowModal(true)
          }}
        >
          <CreateIcon style={{ color: '#95C123' }} />
        </ActionDiv>
        <ActionDiv
          hidden={!approvalRole.allowDelete}
          id={`m-delete-btn-${dataItem.Approval_Code}`}
          onClick={() => {
            setDeleteData({ Id: dataItem.Id })
            setToggleDelete(!toggleDelete)
          }}
        >
          <DeleteIcon style={{ color: '#000000' }} />
        </ActionDiv>
      </RowIcon>
    </td>
  )

  const deleteModal = () => (
    <div>
      <ModalDel
        toggleDialog={() => {
          setToggleDelete(!toggleDelete)
        }}
        submitDelete={submitDelete}
        deleteText='Are you sure to delete the data ?'
      />
    </div>
  )

  const mainGrid = () => (
    <MasterGrid
      height={heightGrid}
      data={state.get}
      total={dataCount}
      codeCell={detailCell}
      dataState={dataState}
      gridColumn={
        !approvalRole.allowEdit && !approvalRole.allowDelete
          ? GridColumn.filter((e) => e.title !== 'Action')
          : GridColumn
      }
      actionCell={actionCell}
      pageSizes={[20, 50, 100]}
      onPageChange={(e) => onPageChange(e)}
      onDataStateChange={(e) => onDataStateChange(e)}
      pending={pending}
    />
  )

  const heightGrid = appSize.height - 190 > 455 ? appSize.height - 190 : 455

  const getLocation = async () => {
    try {
      const response = await ApprovalApi.GetListDataLocation()
      let portLocation = response.data.data
      if (usersLoginReducer && usersLoginReducer.UserLocations) {
        portLocation = portLocation.filter((e) =>
          usersLoginReducer.UserLocations.find((ele) => ele.LocationCode === e.Location_Code)
        )
      }
      setLocation(portLocation)
    } catch (err) {
      return err
    }
  }

  const getType = async () => {
    try {
      const response = await ApprovalApi.GetListDataType()
      setType(response.data.data)
    } catch (err) {
      return err
    }
  }

  const getLevel = async () => {
    try {
      const response = await ApprovalApi.GetListDataEnum()
      setLevel(response.data.data)
    } catch (err) {
      return err
    }
  }

  const fetchData = async ({ skip, take, filterString }) => {
    let res = encodeURIComponent(filterString)
    try {
      setPending(true)
      const dataClient = await ApprovalApi.GetListDataApproval(skip, take, res)
      setState({ get: dataClient.data.data })
      setDataCount(dataClient.data.dataCount)
      setPending(false)
    } catch (error) {
      setPending(false)
      console.log(error)
    }
  }

  useEffect(() => {
    if (JSON.stringify(state.get) === '[]') {
      fetchData(dataState)
      setFirstLoad(false)
    }
  }, [])

  return (
    <WrapperContent>
      <Card>
        <MasterHeader
          title='MASTER DATA Approval'
          allowCreate={approvalRole.allowCreate}
          buttonTitle='Add New'
          onClick={() => {
            handleClickOpen()
            setIsEdit(false)
          }}
        />
        {toggleDelete && deleteModal()}
        {mainGrid()}
      </Card>
      <ModalInput
        flag={flag}
        isEdit={isEdit}
        type={type}
        level={level}
        detail={detail}
        editData={editData}
        location={location}
        fetchData={fetchData}
        showModal={showModal}
        dataState={dataState}
        flagStatus={flagStatus}
        handleClose={handleClose}
      />
      <Alerts
        type={showAlert.type}
        open={showAlert.visible}
        close={setAlert}
        msg={showAlert.msg}
        success={showAlert.success}
      />
    </WrapperContent>
  )
}
export default Approval
