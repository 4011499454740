import styled from 'styled-components'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { Colors, FontSize } from '../../../styles'

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.height}px;
`
export const RowIcon = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: center;
  justify-content: space-around;
`
export const IconEdit = styled(EditIcon)`
  color: #71af00;
`
export const IconDel = styled(DeleteIcon)`
  color: black;
`
export const BtnSave = styled.div`
  margin: 28px 10px 10px auto;
`
export const DivBtnDel = styled.div`
  margin-top: 30px;
  text-align: center;
`
export const DialogImage = styled.div`
  padding: 30px;
  width: 100%;
  height: 104px;
  background-color: #d5e9f1;
`
export const TitleDialog = styled.p`
  float: right;
  margin-left: 30px;
  display: inline;
  font-size: 16px;
  font-weight: bold;
  color: #0082ad;
  text-transform: uppercase;
`
export const IconDialog = styled.img`
  float: left;
  width: 120px;
  height: 120px;
  margin-top: -40px !important;
`
export const DivTitle = styled.div`
  float: left;
`
export const BtnColOption = styled.div`
  display: flex;
  cursor: pointer;
  font-size: 14px;
  padding-right: 10px;
  align-items: center;
  justify-content: space-between;
`
export const RowDetail = styled.td`
  cursor: pointer;
`
export const Form = styled.div`
  flex: 1;
  display: flex;
  padding: 10px 30px;
  flex-direction: column;
`

export const CatWrapper = styled.div`
  padding: 15px;
  margin-top: 5px;
  border-radius: 5px;
  background: ${Colors.backgroundColor};
  box-shadow: 0px 0px 6px #00000029;
`

export const Title = styled.div`
  font-weight: 600;
`
export const ListWrapper = styled.div`
  height: ${(p) => (p.height ? p.height - 65 : 300)}px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 15px;
`
export const CardList = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  margin: 10px 0px 10px 0px;
  font-size: ${FontSize.medium}px;
`

export const BlueDiv = styled.div`
  background: ${Colors.softBlue};
  width: 5px;
  height: 100%;
`
export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`
export const BackgroundParent = styled.div`
  background: #d5d5d5;
  border-radius: 20px;
`

export const BackgroundChild = styled.div`
  background: ${Colors.softBlue};
  width: ${(p) => p.width}%;
  height: 6px;
  border-radius: 20px;
`
export const FixHeight = styled.div`
  height: 65px;
  margin: 10px;
`
