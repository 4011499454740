import getConfig from 'next/config'
import { useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  GET_DATA_VESSEL,
  GET_VESSEL_TYPE,
  GET_VESSEL_SIZE,
  GET_DETAIL_VESSEL,
  GET_VESSEL_CATEGORY,
} from '../../../redux/actions'
import GridColumn from './column.json'
import { VesselAPI, ServiceRequestAPI } from '../../../api'
import ModalInput from './vessel-modal.container'
import { GenerateFilterTerm } from '../../../utils'
import { LoadingContainer, IconDel, IconEdit, RowIcon, ActionCell } from './vessel.styled'
import { Card, ModalDel, Loading, CodeCell, MasterHeader, MasterGrid } from '../../../components'

import { WrapperContent } from '../../../styles/global.styled'

const Vessel = () => {
  const dispatch = useDispatch()
  const { publicRuntimeConfig } = getConfig()
  const appSize = useSelector((state) => state.appReducer)
  const [modal, setModal] = useState(false)
  const [dataDel, setDataDel] = useState(0)
  const [edited, setEdited] = useState(false)
  const [detail, setDetail] = useState(false)
  const [dataGrid, setDataGrid] = useState([])
  const [fileName, setFileName] = useState('')
  const [shipCode, setShipCode] = useState('')
  const [dataItem, setDataItem] = useState([])
  const [loading, setLoading] = useState(false)
  const [dataCount, setDataCount] = useState(0)
  const [showFile, setShowFile] = useState(false)
  const [toggleDelete, setToggleDelete] = useState(false)
  const [regularCode, setRegularCode] = useState('')
  const [nonRegular, setNonRegular] = useState('')
  const [bargeCode, setBargeCode] = useState('')
  const [tsvCode, setTsvCode] = useState('')
  const [tankerCode, setTankerCode] = useState('')
  const [lctCode, setLctCode] = useState('')
  const [rigCode, setRigCode] = useState('')
  const [fileSize, setFileSize] = useState(20)
  const [isRegular, setIsRegular] = useState(true)
  const [imageName, setImageName] = useState('')
  const [imageCode, setImageCode] = useState('')
  const [showImage, setShowImage] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [showAlert, setAlert] = React.useState({
    visible: false,
    msg: '',
    success: false,
    type: '',
  })
  const [dataState, setDataState] = useState({ skip: 0, take: 20, filter: [], filterString: '[]' })
  const height = appSize.height - 190 > 455 ? appSize.height - 190 : 455
  const [pending, setPending] = useState(false)

  const vesselRole = useSelector((state) =>
    state.usersLoginReducer.roleMapping.find((e) => e.activeName.includes('master-data/vessel'))
  )

  const submitDelete = async () => {
    try {
      const response = await VesselAPI.DelDataVessel(dataDel)
      if (response.data && response.data.result) {
        setAlert({
          msg: 'Delete data has been successful',
          success: true,
          visible: true,
          type: 'delete',
        })
        getData(dataState)
      } else {
        setAlert({ msg: 'Cannot delete data', success: false, visible: true, type: 'delete' })
      }
    } catch (err) {
      return err
    }
    setToggleDelete(!toggleDelete)
  }

  useEffect(() => {
    _getFileSize()
    getCategory()
    getSize()
    getType()
    getData(dataState)
    setFirstLoad(false)
  }, [])

  const getDetail = async (e) => {
    await dispatch({
      type: GET_DETAIL_VESSEL,
      payload: e,
    })
  }
  const getCategory = async () => {
    try {
      const response = await VesselAPI.GetListVesselCategory()
      if (response.data && response.data.data)
        await dispatch({
          type: GET_VESSEL_CATEGORY,
          payload: response.data.data,
        })
      const regularData = response.data.data.find((e) => e.Value.toLowerCase() === 'regular')
      const nonRegular = response.data.data.find((e) => e.Value.toLowerCase() === 'non regular')
      if (regularData) {
        setRegularCode(regularData['Code'])
      }
      if (nonRegular) {
        setNonRegular(nonRegular['Code'])
      }
    } catch (err) {
      return err
    }
  }
  const getSize = async () => {
    try {
      const response = await VesselAPI.GetListVesselSize()
      if (response.data && response.data.data) {
        await dispatch({
          type: GET_VESSEL_SIZE,
          payload: response.data.data,
        })
      }
    } catch (err) {
      return err
    }
  }
  const getType = async () => {
    try {
      const response = await VesselAPI.GetListVesselType()
      if (response.data && response.data.data)
        await dispatch({
          type: GET_VESSEL_TYPE,
          payload: response.data.data,
        })
      const bargeData = response.data.data.find((e) => e.Vessel_Type.toLowerCase() === 'barge')
      const lctData = response.data.data.find(
        (e) => e.Vessel_Type.toLowerCase() === 'landing craft tank'
      )
      const tsvData = response.data.data.find((e) => e.Vessel_Type.toLowerCase() === 'tsv')
      const tankerData = response.data.data.find((e) => e.Vessel_Type.toLowerCase() === 'tanker')
      const rigData = response.data.data.find((e) => e.Vessel_Type.toLowerCase() === 'rig')
      if (bargeData) {
        setBargeCode(bargeData['Code'])
      }
      if (lctData) {
        setLctCode(lctData['Code'])
      }
      if (tsvData) {
        setTsvCode(tsvData['Code'])
      }
      if (tankerData) {
        setTankerCode(tankerData['Code'])
      }
      if (rigData) {
        setRigCode(rigData['Code'])
      }
    } catch (err) {
      return err
    }
  }

  const _getFileSize = async () => {
    try {
      const filter =
        '[{"field":"type","type":"text","value":"File_Size"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ServiceRequestAPI.GetAllFromTableEnum(0, 100, filter)
      setFileSize(parseInt(data[0].Value))
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenModal = () => {
    dispatch({
      type: GET_DETAIL_VESSEL,
      payload: {
        Id: null,
        Code: 'VSCXXXX',
        Vessel_Type_Code: '',
        Vessel_Category_Code: '',
        Vessel_Size_Code: '',
        Vessel_Name: '',
        Device_Id: '',
        Imo_No: '',
        Length_Overall: '',
        Dead_Weight: '',
        Depth_Moulded: '',
        Breadth_Moulded: '',
        Draft: '',
        Gross_Tonnage: '',
        Net_Tonnage: '',
        Build_Year: '',
        Speed: '',
        Call_Sign: '',
        Flag: '',
        Mmsi: '',
        Is_Active: true,
        Ship_Particular_File: '',
        Created_By: '',
        Modified_Date: '',
        Modified_By: '',
        Filename: '',
        Vessel_Size: '',
        Contract_No: '',
        Image_File: '',
      },
    })
    setFileName('')
    setShipCode('')
    setImageCode('')
    setImageName('')
    setModal(!modal)
    setEdited(edited)
  }

  const clearModal = () => {
    setModal(false)
    setDetail(false)
    setEdited(false)
    setShowFile(false)
    setShowImage(false)
  }

  const details = ({ dataItem }) => {
    return (
      <>
        <CodeCell
          id='m-detail-btn'
          onClick={() => {
            getDetail(dataItem)
            setDataItem(dataItem)
            setDetail(!detail)
            setEdited(edited)
            setFileName(dataItem.Filename)
            setShipCode(dataItem.Ship_Particular_File)
            setImageName(dataItem.Image_File_Name)
            setImageCode(dataItem.Image_File)
            setShowFile(true)
            setShowImage(true)
            setModal(!modal)
          }}
        >
          {dataItem.Code}
        </CodeCell>
      </>
    )
  }

  const controlPanel = ({ dataItem, colSpan }) => {
    return (
      <td>
        <RowIcon colSpan={colSpan}>
          <ActionCell
            hidden={!vesselRole.allowEdit}
            id='m-edit-btn'
            onClick={() => {
              getDetail(dataItem)
              setDataItem(dataItem)
              setEdited(!edited)
              setFileName(dataItem.Filename)
              setShipCode(dataItem.Ship_Particular_File)
              setImageName(dataItem.Image_File_Name)
              setImageCode(dataItem.Image_File)
              setShowFile(true)
              setShowImage(true)
              setIsRegular(dataItem.Vessel_Category === 'Regular' ? true : false)
              setModal(!modal)
            }}
          >
            <IconEdit />
          </ActionCell>
          <ActionCell
            hidden={!vesselRole.allowDelete}
            id='m-delete-btn'
            onClick={() => {
              setToggleDelete(!toggleDelete)
              setDataDel(dataItem.Id)
            }}
          >
            <IconDel />
          </ActionCell>
        </RowIcon>
      </td>
    )
  }

  const onDataStateChange = ({ data }) => {
    GenerateFilterTerm(data.filter, GridColumn)

    setDataState({
      ...dataState,
      ...data,
      filter: data.filter,
      filterString: GenerateFilterTerm(data.filter, GridColumn),
    })
  }

  useEffect(() => {
    const vessel = setTimeout(() => {
      if(!firstLoad) {
        getData({ ...dataState,
          filterString: GenerateFilterTerm(dataState.filter, GridColumn) })
      }
    }, publicRuntimeConfig.FILTER_TIMEOUT)
    return () => clearTimeout(vessel)
  }, [dataState.filter])

  const onPageChange = (event) => {
    setDataState({
      ...dataState,
      skip: event.page.skip,
      take: event.page.take,
    })

    getData({
      skip: event.page.skip,
      take: event.page.take,
      filterString: dataState.filterString,
    })
  }

  const getData = async ({ skip, take, filterString }) => {
    setLoading(loading)
    try {
      setPending(true)
      const response = await VesselAPI.GetListDataVessel(skip, take, filterString)
      setDataGrid(response.data.data)
      setDataCount(response.data.dataCount)

      await dispatch({
        type: GET_DATA_VESSEL,
        payload: { dataVessel: response.data.data, count: response.data.dataCount },
      })
      setPending(false)
    } catch (err) {
      setPending(false)
      return err
    }
    setLoading(loading)
  }

  const header = () => {
    return (
      <MasterHeader
        allowCreate={vesselRole.allowCreate}
        title='MASTER DATA VESSEL'
        buttonTitle='Add New'
        onClick={() => {
          handleOpenModal()
        }}
      />
    )
  }

  const mainGrid = () => {
    return (
      <MasterGrid
        id='m_vessel_grid'
        data={dataGrid}
        total={dataCount}
        height={height}
        dataState={dataState}
        gridColumn={
          !vesselRole.allowEdit && !vesselRole.allowDelete
            ? GridColumn.filter((e) => e.title !== 'Action')
            : GridColumn
        }
        actionCell={controlPanel}
        codeCell={details}
        onPageChange={(e) => onPageChange(e)}
        onDataStateChange={(e) => onDataStateChange(e)}
        pageSizes={[20, 50, 100]}
        pending={pending}
      ></MasterGrid>
    )
  }
  return (
    <WrapperContent>
      <Card>
        {header()}
        {loading ? (
          <LoadingContainer height={height}>
            <Loading />
          </LoadingContainer>
        ) : (
          mainGrid()
        )}
        <ModalInput
          edit={edited}
          detail={detail}
          modal={modal}
          getData={getData}
          dataState={dataState}
          setModal={() => clearModal()}
          fileName={fileName}
          shipCode={shipCode}
          imageName={imageName}
          imageCode={imageCode}
          showFile={showFile}
          setShowFile={setShowFile}
          showImage={showImage}
          setShowImage={setShowImage}
          showAlert={showAlert}
          setAlert={setAlert}
          dataItem={dataItem}
          regularCode={regularCode}
          nonRegular={nonRegular}
          bargeCode={bargeCode}
          lctCode={lctCode}
          tsvCode={tsvCode}
          rigCode={rigCode}
          tankerCode={tankerCode}
          fileSize={fileSize}
          isRegular={isRegular}
          setIsRegular={setIsRegular}
        />
        {toggleDelete && (
          <ModalDel
            deleteText='Are you sure to delete the data ?'
            toggleDialog={() => setToggleDelete(!toggleDelete)}
            submitDelete={submitDelete}
          />
        )}
      </Card>
    </WrapperContent>
  )
}

export default Vessel
