import * as Yup from 'yup'
import moment from 'moment'
import { useState } from 'react'
import { useFormik } from 'formik'

import { Form } from './jetty.styled'
import { JettyApi } from '../../../api'
import { checkIsEmpty, ConvertLocalTimeGet, ConvertLocalTimePost, Lookup } from '../../../utils'
import { Input, Alerts, MasterModal, InputSelect } from '../../../components'
import LogAPI from '../../../api/master-data/log'
import { messageErrorFormikLog } from '../../../utils/message'

const ModalInput = (props) => {
  const isEdit = props.edit
  const isDetail = props.detail
  const [multiClick, setMultClick] = useState(0)
  const [showAlert, setAlert] = React.useState({
    visible: false,
    msg: '',
    success: false,
    type: '',
  })

  const handleSave = async (passing) => {
    if (props.editData.Id == null) {
      try {
        const response = await JettyApi.PostDataJetty(passing)
        if (response.data && response.data.result) {
          props.fetchData(props.dataState)
          props.handleClose()
          FormInput.handleReset()
          setAlert({
            msg: 'Add data has been successful',
            success: true,
            visible: true,
            type: 'insert',
          })
        } else {
          setAlert({
            msg: 'Jetty data already exist',
            success: false,
            visible: true,
            type: 'insert',
          })
        }
      } catch (err) {
        setAlert({
          msg: 'Jetty data already exist',
          success: false,
          visible: true,
          type: 'insert',
        })
        return err
      }
    } else {
      try {
        const response = await JettyApi.EditDataJetty(passing)
        if (response.data && response.data.result) {
          props.fetchData(props.dataState)
          props.handleClose()
          FormInput.handleReset()
          setAlert({
            msg: 'Update data has been successful',
            success: true,
            visible: true,
            type: 'update',
          })
        } else {
          setAlert({
            msg: 'Jetty data already exist',
            success: false,
            visible: true,
            type: 'update',
          })
        }
      } catch (err) {
        return err
      }
    }
    return true
  }

  const FormInput = useFormik({
    enableReinitialize: true,
    initialValues: {
      Id: props.editData.Id ? props.editData.Id : null,
      Jetty_Code: props.editData.Jetty_Code,
      Draft: props.editData.Draft ? props.editData.Draft : 0,
      Length: props.editData.Length ? props.editData.Length : 0,
      Remarks: props.editData.Remarks ? props.editData.Remarks : '',
      Is_Active: props.editData.Is_Active ? props.editData.Is_Active : false,
      Max_Vessel: props.editData.Max_Vessel ? props.editData.Max_Vessel : 0,
      Jetty_Name: props.editData.Jetty_Name ? props.editData.Jetty_Name : '',
      Modified_By: props.editData.Modified_By ? props.editData.Modified_By : '',
      Location_Code: props.editData.Location_Code ? props.editData.Location_Code : '',
      Location: props.editData.Location ? props.editData.Location : '',
    },
    validationSchema: Yup.object({
      Remarks: Yup.string(),
      Is_Active: Yup.boolean(),
      Location_Code: Yup.number().required('*Location*'),
      Draft: Yup.number('*record no available*').required('*Draft*').min(1, '*Draft*'),
      Length: Yup.number('*record no available*').required('*Length*').min(1, '*Length*'),
      Max_Vessel: Yup.number('*record no available*')
        .required('*Max Vessel*')
        .min(1, '*Max Vessel*'),
      Jetty_Name: Yup.string()
        .required('*Jetty Name*')
        .max(100, 'the characters you entered are too long'),
    }),
    onSubmit: async (values, form) => {
      const  location_desc = props.location.find(e=>e.Id === values.Location_Code).Location_Text

      values['Created_Date']= ConvertLocalTimePost(location_desc, new Date())
      values['Modified_Date']= ConvertLocalTimePost(location_desc, new Date())
      if (checkIsEmpty(values.Jetty_Name)) {
        form.setValues({
          ...values,
          Jetty_Name: '',
        })
      } else {
        setMultClick(multiClick + 1)
        if (multiClick === 0) {
          await handleSave(values)
        }
      }
    },
  })

  const insertLog = async () => {
    const detail =
      Object.entries(FormInput.touched).length > 0
        ? messageErrorFormikLog(FormInput.errors)
        : Lookup.LOG_MESSAGE.NO_INPUT

    const dataInsert = {
      Timestamp: moment().toISOString(),
      Action: isEdit ? Lookup.LOG_ACTION.UPDATE : Lookup.LOG_ACTION.ADD,
      Module: Lookup.LOG_MODULE.MASTER_DATA,
      Submodule: Lookup.LOG_SUB_MODULE.MASTER_JETTY,
      Detail: detail,
      Logtype: Lookup.LOG_TYPE.SYSTEM_LOG,
      Errorlevel: Lookup.LOG_ERROR_LEVEL.ERROR,
      From: '',
      To: '',
      Refno: FormInput.values.Code,
    }

    if (
      (Object.entries(FormInput.touched).length === 0 && !isEdit) || //submit w/o touch field
      (Object.entries(FormInput.errors).length > 0 && multiClick === 0) // submit after touch field
    ) {
      const { data } = await LogAPI.PostDataLog(dataInsert)
      if (data && data.result) return data.result
    } else return false
  }
  const closeResetFormik = () => {
    props.handleClose()
    FormInput.handleReset()
  }

  return (
    <>
      <MasterModal
        maxWidth='lg'
        width={37}
        add={!isDetail}
        title='Master Data'
        showModal={props.showModal}
        handleClose={closeResetFormik}
        subTitle={
          isEdit
            ? 'EDIT MASTER DATA JETTY'
            : isDetail
              ? 'DETAIL MASTER DATA JETTY'
              : 'ADD MASTER DATA JETTY'
        }
        handleSave={() => {
          insertLog()
          FormInput.handleSubmit()
        }}
      >
        <Form>
          <Input
            form={FormInput}
            id='m_jetty-mdl-code-tf'
            name='Jetty_Code'
            label='Jetty Code'
            value={FormInput.values.Jetty_Code}
            InputProps={{ readOnly: true }}
            inputProps={{
              tabIndex: 1,
            }}
          />

          <Input
            form={FormInput}
            id='m_jetty-mdl-jetty-name-tf'
            name='Jetty_Name'
            label='Jetty Name'
            value={FormInput.values.Jetty_Name}
            InputProps={{ readOnly: props.flag }}
            inputProps={{
              tabIndex: 3,
            }}
          />

          <Input
            form={FormInput}
            type='number'
            id='m_jetty-mdl-draft-tf'
            name='Draft'
            label='Draft'
            InputProps={{ readOnly: props.flag }}
            inputProps={{
              tabIndex: 5,
            }}
          />

          <Input
            form={FormInput}
            id='m_jetty-mdl-remark-tf'
            rowsMax={5}
            variant='outlined'
            name='Remarks'
            label='Remarks'
            multiline={true}
            InputProps={{ readOnly: props.flag }}
            value={FormInput.values.Remarks}
            inputProps={{
              tabIndex: 7,
            }}
          />

          {props.flagStatus && (
            <InputSelect
              id='m_jetty-mdl-status-tf'
              label='Status'
              name='Is_Active'
              options={[
                { text: 'Enable', value: true },
                { text: 'Disable', value: false },
              ]}
              optionText='text'
              optionValueKey='value'
              readOnly={props.flag}
              form={FormInput}
              tabIndex={8}
            />
          )}
        </Form>
        <Form>
          <InputSelect
            id='port_location'
            label='Port Location'
            name='Location_Code'
            options={props.location}
            optionText='Location_Text'
            optionValueKey='Id'
            readOnly={props.flag}
            form={FormInput}
            tabIndex={2}
          />

          <Input
            form={FormInput}
            type='number'
            id='m_jetty-mdl-length-tf'
            name='Length'
            label='Length'
            onBlur={() => {
              parseFloat().toFixed(2)
            }}
            InputProps={{ readOnly: props.flag }}
            inputProps={{
              tabIndex: 4,
            }}
          />

          <Input
            form={FormInput}
            type='number'
            id='m_jetty-mdl-max-vessel-tf'
            name='Max_Vessel'
            label='Max Vessel'
            InputProps={{ readOnly: props.flag }}
            inputProps={{
              tabIndex: 6,
            }}
          />

          {props.flagStatus && (
            <Input
              form={FormInput}
              id='m_jetty-mdl-modify-by'
              name='Modified_By'
              label='Modified By'
              InputProps={{ readOnly: true }}
            />
          )}

          {props.flagStatus && (
            <Input
              id='m_jetty-mdl-modify-on-tf'
              name='Modified_Date'
              label='Modified On'
              value={ConvertLocalTimeGet(props.editData.Location, props.editData.Modified_Date, 'DD/MM/YYYY HH:mm:ss')}
              InputProps={{ readOnly: true }}
            />
          )}
        </Form>
      </MasterModal>
      <Alerts
        type={showAlert.type}
        open={showAlert.visible}
        close={() => {
          setMultClick(0)
          setAlert({ ...showAlert, visible: false })
        }}
        msg={showAlert.msg}
        success={showAlert.success}
      />
    </>
  )
}
export default ModalInput
