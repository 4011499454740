import styled from 'styled-components'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import MuiDialogTitle from '@material-ui/core/DialogTitle'

export const Img = styled.img`
  margin-left: 40px;
`
export const Td = styled.td`
  cursor: pointer;
`
export const Form = styled.div`
  flex: 1;
  display: flex;
  padding: 10px 30px;
  flex-direction: column;
`
export const ActionDiv = styled.div`
  cursor: pointer;
`
export const DivImg = styled.div`
  top: 316px;
  opacity: 1;
  left: 556px;
  width: 832px;
  height: 104px;
  display: flex;
  background: #00bbff33 0% 0% no-repeat padding-box;
`
export const BtnSave = styled.div`
  padding-top: 24px;
  margin: 15px 50px 90px auto;
`
export const TitleTable = styled.p`
  float: left;
  font-size: 24px;
`
export const DotIcon = styled.div`
  color: #95c123;
  margin-top: 7px;
  display: ruby-text;
`
export const ButtonAdd = styled.div`
  float: right;
  display: flex;
  font-size: 17px;
  margin-top: auto;
`
export const DivBtnDel = styled.div`
  margin-top: 30px;
  text-align: center;
`
export const SureDelete = styled.div`
  text-align: center;
  padding-top: 24px;
  font-weight: bold;
`
export const Container2 = styled.div`
  width: 100%;
  margin-top: 80px;
  position: relative;
`
export const DivContent = styled.div`
  height: 78.8px;
`
export const Container1 = styled.div`
  width: 100%;
  padding: 60px;
  position: realtive;
`
export const DivIconDel = styled.div`
  width: 100%;
  height: 85px;
`
export const TextMaster = styled.p`
  color: white;
`
export const TitleBarModal = styled.p`
  margin: 42px 30px 0px 32px;
  text-align: left;
  font: normal normal bold 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #0a81ad;
  text-transform: uppercase;
  opacity: 1;
`
export const TitleDelete = styled.div`
  opacity: 1;
  top: 49px;
  left: 108px;
  width: 100%;
  height: 37px;
  color: #343434;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: capitalize;
  font: normal normal bold 30px/14px Montserrat;
`
export const IconBtnAdd = styled.button`
  height: 34px;
  border: none;
  display: inline;
  text-align: center;
`
export const IconEdit = styled(EditIcon)`
  color: #71af00;
`
export const IconDel = styled(DeleteIcon)`
  width: 40px !important;
  height: 40px;
`
export const RowIcon = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: center;
  justify-content: space-around;
`
export const BtnColOption = styled.div`
  cursor: pointer;
  font-size: 14px;
  justify-content: space-between;
  margin: 15px 50px 90px auto;
`
export const DialogMuiTitle = styled(MuiDialogTitle)`
  color: white;
  height: 45px;
  padding: 10px;
  backgroundcolor: #252525;
`
