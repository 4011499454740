import * as Yup from 'yup'
import moment from 'moment'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Input, Alerts, MasterModal, InputSelect } from '../../../components'
import { Form } from './activity.styled'
import { ActivityAPI, LogAPI } from '../../../api'
import { messageErrorFormikLog } from '../../../utils/message'
import { Lookup } from '../../../utils'

const currencies = [
  {
    value: true,
    label: 'Enable',
  },
  {
    value: false,
    label: 'Disable',
  },
]

const ModalInput = (props) => {
  const isEdit = props.edit
  const isDetail = props.detail

  const [multiClick, setMultClick] = useState(0)
  const [showAlert, setAlert] = useState({ visible: false, msg: '', success: false, type: '' })
  const { dataCategory, dataDetail } = useSelector((state) => state.activityReducer)
  const DateTime = moment(dataDetail.Modified_Date).format('DD/MM/YYYY HH:mm:ss')

  const FormInput = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...dataDetail,
      Modified_Date: DateTime,
      Code: isEdit || isDetail ? dataDetail.Code : 'ATCXXXX',
    },
    validationSchema: Yup.object({
      Code: Yup.string(),
      Is_Active: Yup.bool(),
      Activity_Name: Yup.string().required('*Activity name*').trim(),
      Sr_Category_Code: Yup.string().required('*Category*'),
      Percentage: Yup.number().required('*Percentage*'),
    }),
    onSubmit: async (values, form) => {
      setMultClick(multiClick + 1)
      let formData = {}
      Object.keys(values)
        .filter(
          (v) =>
            v == 'Activity_Name' || v == 'Sr_Category_Code' || v == 'Percentage' || v == 'Is_Active'
        )
        .forEach((v) => {
          formData[v] = values[v]
        })
      if (isEdit) {
        try {
          if (multiClick === 0) {
            const response = await ActivityAPI.EditDataActivity(values.Id, formData)
            if (response.data && response.data.result) {
              form.resetForm()
              props.setModal(false)
              setAlert({
                msg: 'Update data has been successful',
                success: true,
                visible: true,
                type: 'update',
              })
              props.getDataCategoryPercent()
              props.getDataActivity()
            } else {
              setAlert({ msg: response.data.mesage, success: false, visible: true, type: 'update' })
            }
          }
        } catch (err) {
          return err
        }
      } else {
        try {
          if (multiClick === 0) {
            const response = await ActivityAPI.PostDataActivity({ ...formData, Is_Active: true })
            if (response.data && response.data.result) {
              form.resetForm()
              props.setModal(false)
              setAlert({
                msg: 'Add data has been successful',
                success: true,
                visible: true,
                type: 'insert',
              })
              props.getDataCategoryPercent()
              props.getDataActivity()
            } else {
              setAlert({ msg: response.data.mesage, success: false, visible: true, type: 'insert' })
            }
          }
        } catch (err) {
          return err
        }
      }
    },
  })

  const insertLog = async () => {
    const detail =
      Object.entries(FormInput.touched).length > 0
        ? messageErrorFormikLog(FormInput.errors)
        : Lookup.LOG_MESSAGE.NO_INPUT

    const dataInsert = {
      Timestamp: moment().toISOString(),
      Action: isEdit ? Lookup.LOG_ACTION.UPDATE : Lookup.LOG_ACTION.ADD,
      Module: Lookup.LOG_MODULE.MASTER_DATA,
      Submodule: Lookup.LOG_SUB_MODULE.MASTER_ACTIVITY,
      Detail: detail,
      Logtype: Lookup.LOG_TYPE.SYSTEM_LOG,
      Errorlevel: Lookup.LOG_ERROR_LEVEL.ERROR,
      From: '',
      To: '',
      Refno: FormInput.values.Code,
    }

    if (
      (Object.entries(FormInput.touched).length === 0 && !isEdit) || //submit w/o touch field
      (Object.entries(FormInput.errors).length > 0 && multiClick === 0) // submit after touch field
    ) {
      const { data } = await LogAPI.PostDataLog(dataInsert)
      if (data && data.result) return data.result
    } else return false
  }

  return (
    <div>
      <MasterModal
        id='m_actv_mrt_mdl'
        maxWidth='lg'
        width={33}
        add={!isDetail}
        title='Master Data'
        showModal={props.modal}
        handleClose={() => {
          FormInput.resetForm()
          FormInput.setSubmitting(false)
          props.setModal()
        }}
        subTitle={
          isEdit
            ? 'Edit Master Data Activity'
            : isDetail
              ? 'Detail Master Data Activity'
              : 'Add Master Data Activity'
        }
        handleSave={() => {
          insertLog()
          FormInput.handleSubmit()
        }}
      >
        <Form>
          <Input
            form={FormInput}
            name='Code'
            label='Activity Code'
            id='m_actv_code_txt'
            InputProps={{ readOnly: true }}
            inputProps={{
              tabIndex: 1,
            }}
          />
          <Input
            form={FormInput}
            name='Activity_Name'
            label='Activity Name'
            id='m_actv_name_txt'
            InputProps={{ readOnly: isDetail }}
            inputProps={{
              tabIndex: 3,
            }}
          />
          {(isDetail || isEdit) && (
            <Input
              form={FormInput}
              name='Modified_By'
              label='Modified By'
              id='m_actv_modby_txt'
              InputProps={{ readOnly: isDetail || isEdit }}
            />
          )}

          {(isDetail || isEdit) && (
            <Input
              form={FormInput}
              name='Modified_Date'
              label='Modified On'
              id='m_actv_modon_txt'
              InputProps={{ readOnly: isDetail || isEdit }}
            />
          )}
        </Form>
        <Form>
          <InputSelect
            id='category_txt'
            label='Category'
            name='Sr_Category_Code'
            options={dataCategory}
            optionText='Value'
            optionValueKey='Code'
            readOnly={isDetail ? true : isEdit ? true : false}
            form={FormInput}
            tabIndex={2}
          />
          <Input
            type='number'
            form={FormInput}
            name='Percentage'
            label='Percentage'
            id='m_actv_percent_txt'
            InputProps={{ readOnly: isDetail }}
            inputProps={{
              tabIndex: 4,
            }}
          />
          {(isDetail || isEdit) && (
            <InputSelect
              id='m_actv_status_txt'
              label='Status'
              name='Is_Active'
              options={currencies}
              optionText='label'
              optionValueKey='value'
              readOnly={isEdit ? false : true}
              form={FormInput}
              tabIndex={5}
            />
          )}
        </Form>
      </MasterModal>
      <Alerts
        type={showAlert.type}
        open={showAlert.visible}
        close={() => {
          setMultClick(0)
          setAlert({ ...showAlert, visible: false })
        }}
        msg={showAlert.msg}
        success={showAlert.success}
      />
    </div>
  )
}
export default ModalInput
