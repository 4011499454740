import { useState } from 'react'
import * as Yup from 'yup'
import moment from 'moment'
import { useFormik } from 'formik'
import { Input, Alerts, MasterModal, InputSelect } from '../../../components'

import { Form } from './approval.styled'
import { ApprovalApi } from '../../../api'
import LogAPI from '../../../api/master-data/log'
import { messageErrorFormikLog } from '../../../utils/message'
import { ConvertLocalTimeGet, ConvertLocalTimePost, Lookup } from '../../../utils'

const ModalInput = (props) => {
  const { isEdit, isDetail } = props
  const [multiClick, setMultClick] = useState(0)
  const [showAlert, setAlert] = React.useState({
    visible: false,
    msg: '',
    success: false,
    type: '',
  })

  const handleSave = async (passing) => {
    if (props.editData.Id == null) {
      try {
        const response = await ApprovalApi.PostDataApproval(passing)
        if (response.data && response.data.result) {
          props.fetchData(props.dataState)
          props.handleClose()
          FormInput.handleReset()
          setAlert({
            msg: 'Add data has been successful',
            success: true,
            visible: true,
            type: 'insert',
          })
        } else {
          setAlert({
            msg: 'Approval data already exist',
            success: false,
            visible: true,
            type: 'insert',
          })
        }
      } catch (err) {
        setAlert({
          msg: 'Approval data already exist',
          success: false,
          visible: true,
          type: 'insert',
        })
        return err
      }
    } else {
      try {
        const response = await ApprovalApi.EditDataApproval(passing)
        if (response.data && response.data.result) {
          props.fetchData(props.dataState)
          props.handleClose()
          FormInput.handleReset()
          setAlert({
            msg: 'Update data has been successful',
            success: true,
            visible: true,
            type: 'update',
          })
        } else {
          setAlert({
            msg: 'Approval data already exist',
            success: false,
            visible: true,
            type: 'update',
          })
        }
      } catch (err) {
        return err
      }
    }
    return true
  }

  const FormInput = useFormik({
    enableReinitialize: true,
    initialValues: {
      Approval_Code: props.editData.Approval_Code,
      Id: props.editData.Id ? props.editData.Id : null,
      Is_Active: props.editData.Is_Active ? props.editData.Is_Active : false,
      Modified_By: props.editData.Modified_By ? props.editData.Modified_By : '',
      Location_Code: props.editData.Location_Code ? props.editData.Location_Code : '',
      Approval_Level_Code: props.editData.Approval_Level_Code
        ? props.editData.Approval_Level_Code
        : '',
      Approval_Type_Code: props.editData.Approval_Type_Code
        ? props.editData.Approval_Type_Code
        : '',
    },
    validationSchema: Yup.object({
      Location_Code: Yup.number().required('*Location*'),
      Approval_Level_Code: Yup.string().required('*Approval Level*'),
      Approval_Type_Code: Yup.string().required('*Approval Type*'),
    }),
    onSubmit: async (values) => {
      const  location_desc = props.location.find(e=>e.Id === values.Location_Code).Location_Text

      values['Created_Date']= new Date(ConvertLocalTimePost(location_desc, new Date()))
      values['Modified_Date']= new Date(ConvertLocalTimePost(location_desc, new Date()))

      setMultClick(multiClick + 1)
      if (multiClick === 0) {
        await handleSave(values)
      }
    },
  })

  const insertLog = async () => {
    const detail =
      Object.entries(FormInput.touched).length > 0
        ? messageErrorFormikLog(FormInput.errors)
        : Lookup.LOG_MESSAGE.NO_INPUT
    const dataInsert = {
      Timestamp: moment().toISOString(),
      Action: isEdit ? Lookup.LOG_ACTION.UPDATE : Lookup.LOG_ACTION.ADD,
      Module: Lookup.LOG_MODULE.MASTER_DATA,
      Submodule: Lookup.LOG_SUB_MODULE.MASTER_ACTIVITY,
      Detail: detail,
      Logtype: Lookup.LOG_TYPE.SYSTEM_LOG,
      Errorlevel: Lookup.LOG_ERROR_LEVEL.ERROR,
      From: '',
      To: '',
      Refno: FormInput.values.Approval_Code,
    }

    if (
      (Object.entries(FormInput.touched).length === 0 && !isEdit) || //submit w/o touch field
      (Object.entries(FormInput.errors).length > 0 && multiClick === 0) // submit after touch field
    ) {
      const { data } = await LogAPI.PostDataLog(dataInsert)
      if (data && data.result) return data.result
    } else return false
  }
  const closeResetFormik = () => {
    props.handleClose()
    FormInput.handleReset()
  }

  return (
    <>
      <MasterModal
        maxWidth='lg'
        width={35}
        add={!isDetail}
        title='Master Data'
        showModal={props.showModal}
        handleClose={closeResetFormik}
        subTitle={
          isEdit
            ? 'EDIT MASTER DATA APPROVAL'
            : isDetail
              ? 'DETAIL MASTER DATA APPROVAL'
              : 'ADD MASTER DATA APPROVAL'
        }
        handleSave={() => {
          insertLog()
          FormInput.handleSubmit()
        }}
      >
        <Form>
          <Input
            form={FormInput}
            id='m_approv-mdl-code-tf'
            name='Approval_Code'
            label='Approval Code'
            value={FormInput.values.Approval_Code}
            InputProps={{ readOnly: true }}
            inputProps={{
              tabIndex: 1,
            }}
          />
          <InputSelect
            id='port_location'
            label='Port Location'
            name='Location_Code'
            options={props.location}
            optionText='Location_Text'
            optionValueKey='Id'
            readOnly={isDetail}
            form={FormInput}
            tabIndex={3}
          />
          {props.flagStatus && (
            <Input
              form={FormInput}
              id='m_approval-mdl-modify-by'
              name='Modified_By'
              label='Modified By'
              InputProps={{ readOnly: true }}
            />
          )}
        </Form>
        <Form>
          <InputSelect
            id='m_approval-mdl-level-tf'
            label='Approval Level'
            name='Approval_Level_Code'
            options={props.level}
            optionText='Value'
            optionValueKey='Code'
            readOnly={props.flag}
            form={FormInput}
            tabIndex={2}
          />
          <InputSelect
            id='m_approval-mdl-type-tf'
            label='Approval Type'
            name='Approval_Type_Code'
            options={props.type}
            optionText='Description'
            optionValueKey='Role_Code'
            readOnly={props.flag}
            form={FormInput}
            tabIndex={4}
          />
          {props.flagStatus && (
            <InputSelect
              id='m_Approval-mdl-status-tf'
              label='Status'
              name='Is_Active'
              options={[
                { text: 'Enable', value: true },
                { text: 'Disable', value: false },
              ]}
              optionText='text'
              optionValueKey='value'
              readOnly={props.flag}
              form={FormInput}
              tabIndex={5}
            />
          )}

          {props.flagStatus && (
            <Input
              id='m_approval-mdl-modify-on-tf'
              name='Modified_Date'
              label='Modified On'
              value={ConvertLocalTimeGet(props.editData.Location_Text, props.editData.Modified_Date, 'DD/MM/YYYY HH:mm:ss')}
              InputProps={{ readOnly: true }}
            />
          )}
        </Form>
      </MasterModal>
      <Alerts
        type={showAlert.type}
        open={showAlert.visible}
        close={() => {
          setMultClick(0)
          setAlert({ ...showAlert, visible: false })
        }}
        msg={showAlert.msg}
        success={showAlert.success}
      />
    </>
  )
}
export default ModalInput
