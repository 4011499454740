import styled from 'styled-components'

export const Form = styled.div`
  flex: 1;
  display: flex;
  padding: 10px 30px;
  flex-direction: column;
`
export const ActionDiv = styled.div`
  cursor: pointer;
`
export const RowIcon = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: center;
  justify-content: space-around;
`
