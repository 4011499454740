import getConfig from 'next/config'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import GridColumn from './column.json'
import Grid from '@material-ui/core/Grid'
import { ActivityAPI } from '../../../api'
import { GenerateFilterTerm } from '../../../utils'
import ModalInput from './activity-modal-container'

import { WrapperContent, ActionCell } from '../../../styles/global.styled'
import {
  Flex,
  Title,
  RowIcon,
  IconDel,
  BlueDiv,
  IconEdit,
  CardList,
  FixHeight,
  CatWrapper,
  ListWrapper,
  BackgroundChild,
  BackgroundParent,
  LoadingContainer,
} from './activity.styled'
import { GET_ACTIVITY_LIST, GET_CATEGORIES, GET_ACTIVITY_DETAIL } from '../../../redux/actions'
import {
  Card,
  Alerts,
  Loading,
  CodeCell,
  ModalDel,
  MasterGrid,
  MasterHeader,
} from '../../../components'

const Activity = () => {
  const { publicRuntimeConfig } = getConfig()
  const appSize = useSelector((state) => state.appReducer)
  const dispatch = useDispatch()
  const { dataActivity, dataCount } = useSelector((state) => state.activityReducer)
  const activityRole = useSelector((state) =>
    state.usersLoginReducer.roleMapping.find((e) => e.activeName.includes('master-data/activity'))
  )
  const height = appSize.height - 190 > 455 ? appSize.height - 190 : 455

  const [modal, setModal] = useState(false)
  const [detail, setDetail] = useState(false)
  const [edited, setEdited] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataDel, setDataDel] = useState({ Id: 0 })
  const [toggleDelete, setToggleDelete] = useState(false)
  const [dataCategoryPercent, setDataCategoryPercent] = useState([])
  const [showAlert, setAlert] = useState({ visible: false, msg: '', success: false, type: '' })
  const [dataState, setDataState] = useState({ skip: 0, take: 20, filter: [], filterString: '[]' })
  const [pending, setPending] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    getData(dataState)
    getCategory()
    getDataCategoryPercent()
    setFirstLoad(false)
  }, [])

  const getDataCategoryPercent = async () => {
    try {
      const response = await ActivityAPI.GetDataCategoryPercent()

      if (response && response.data) {
        setDataCategoryPercent(response.data.data)
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  const onDataStateChange = ({ data }) => {
    setDataState({
      ...dataState,
      ...data,
      filter: data.filter,
      filterString: GenerateFilterTerm(data.filter, GridColumn),
    })
  }

  useEffect(() => {
    const delay = setTimeout(() => {
      if(!firstLoad) {
        getData({ ...dataState,
          filterString: GenerateFilterTerm(dataState.filter, GridColumn) })
      }
    }, publicRuntimeConfig.FILTER_TIMEOUT)
    return () => clearTimeout(delay)
  }, [dataState.filter])

  const onPageChange = (event) => {
    setDataState({
      ...dataState,
      skip: event.page.skip,
      take: event.page.take,
    })

    getData({
      skip: event.page.skip,
      take: event.page.take,
      filterString: dataState.filterString,
    })
  }

  const getData = async ({ skip, take, filterString }) => {
    setLoading(loading)
    try {
      setPending(true)
      const response = await ActivityAPI.GetListDataActivity(skip, take, filterString)
      await dispatch({
        type: GET_ACTIVITY_LIST,
        payload: {
          data: response.data.data,
          count: response.data.dataCount,
        },
      })
      setPending(false)
    } catch (err) {
      setPending(false)
      return err
    }
    setLoading(loading)
  }

  const getDetail = async (e) => {
    await dispatch({
      type: GET_ACTIVITY_DETAIL,
      payload: e,
    })
  }

  const getCategory = async () => {
    try {
      const response = await ActivityAPI.GetListCategory()
      await dispatch({
        type: GET_CATEGORIES,
        payload: response.data.data,
      })
    } catch (err) {
      return err
    }
  }

  const deleteData = async (id) => {
    try {
      const response = await ActivityAPI.DelDataActivity(id)
      if (response.data && response.data.result) {
        setAlert({
          msg: 'Delete data has been successful',
          success: true,
          visible: true,
          type: 'delete',
        })
        getData(dataState)
        getDataCategoryPercent()
        setToggleDelete(!toggleDelete)
      } else {
        setAlert({ msg: 'Cannot delete data', success: false, visible: true, type: 'delete' })
      }
    } catch (err) {
      return err
    }
  }

  const handleOpenModal = () => {
    dispatch({
      type: GET_ACTIVITY_DETAIL,
      payload: {
        Id: '',
        Code: '',
        Activity_Name: '',
        Sr_Category_Code: '',
        Percentage: 0,
        Is_Active: '',
        Modified_Date: '',
      },
    })
    setModal(!modal)
  }

  const _clearModal = () => {
    setModal(false)
    setDetail(false)
    setEdited(false)
  }

  const deleteModal = (id) => (
    <div>
      <ModalDel
        id='m_actv_delete_mdl'
        toggleDialog={() => setToggleDelete(!toggleDelete)}
        submitDelete={() => deleteData(id)}
        deleteText='Are you sure to delete the data ?'
      />
    </div>
  )

  const detailCell = ({ dataItem }) => {
    return (
      <CodeCell
        id='m_actv_detail_btn'
        onClick={() => {
          getDetail(dataItem)
          setModal(!modal)
          setDetail(!detail)
        }}
      >
        {dataItem.Code}
      </CodeCell>
    )
  }

  const actionCell = ({ dataItem, colSpan }) => {
    return (
      <td>
        <RowIcon colSpan={colSpan} id='m_actv_action_btn'>
          <ActionCell
            hidden={!activityRole.allowEdit}
            id='m-edit-btn'
            onClick={() => {
              getDetail(dataItem)
              setModal(!modal)
              setEdited(!edited)
            }}
          >
            <IconEdit />
          </ActionCell>
          <ActionCell
            hidden={!activityRole.allowDelete}
            id='m_actv_delete_btn'
            onClick={() => {
              setToggleDelete(!toggleDelete)
              setDataDel({ Id: dataItem.Id })
            }}
          >
            <IconDel />
          </ActionCell>
        </RowIcon>
      </td>
    )
  }

  const header = () => {
    return (
      <MasterHeader
        allowCreate={activityRole.allowCreate}
        title='MASTER DATA ACTIVITY'
        buttonTitle='Add New'
        onClick={() => {
          handleOpenModal()
        }}
      />
    )
  }

  const _grid = () => {
    return (
      <MasterGrid
        id='m_actv_grid'
        height={height}
        data={dataActivity}
        total={dataCount}
        codeCell={detailCell}
        dataState={dataState}
        gridColumn={
          !activityRole.allowEdit && !activityRole.allowDelete
            ? GridColumn.filter((e) => e.title !== 'Action')
            : GridColumn
        }
        actionCell={actionCell}
        pageSizes={[20, 50, 100]}
        onPageChange={(e) => onPageChange(e)}
        onDataStateChange={(e) => onDataStateChange(e)}
        pending={pending}
      />
    )
  }
  const _CategoryPercent = () => {
    return (
      <CatWrapper>
        <Title>Category Percentage</Title>
        <ListWrapper height={height}>
          {dataCategoryPercent.map((e, index) => (
            <CardList key={index}>
              <Grid container spacing={0}>
                <Grid item md={1} style={{ maxWidth: '5%' }}>
                  <BlueDiv></BlueDiv>
                </Grid>
                <Grid item md={11}>
                  <FixHeight>
                    <Flex>
                      <div>{e.Value_Category}</div>
                      <div>{e.Sum}%</div>
                    </Flex>
                    <div style={{ marginTop: '30px' }}>
                      <BackgroundParent>
                        <BackgroundChild width={e.Sum}></BackgroundChild>
                      </BackgroundParent>
                    </div>
                  </FixHeight>
                </Grid>
              </Grid>
            </CardList>
          ))}
        </ListWrapper>
      </CatWrapper>
    )
  }

  return (
    <WrapperContent>
      <Card>
        {header()}
        {toggleDelete && deleteModal(dataDel.Id)}
        {loading ? (
          <LoadingContainer height={height}>
            <Loading />
          </LoadingContainer>
        ) : (
          <Grid container spacing={2}>
            <Grid item md={2}>
              {_CategoryPercent()}
            </Grid>
            <Grid item md={10}>
              {_grid()}
            </Grid>
          </Grid>
        )}
        <ModalInput
          id='m_actv_mdl'
          edit={edited}
          detail={detail}
          getDataCategoryPercent={getDataCategoryPercent}
          getDataActivity={() => getData(dataState)}
          dataState={dataState}
          modal={modal}
          setModal={() => _clearModal()}
        />
        <Alerts
          type={showAlert.type}
          open={showAlert.visible}
          close={setAlert}
          msg={showAlert.msg}
          success={showAlert.success}
        />
      </Card>
    </WrapperContent>
  )
}
export default Activity
