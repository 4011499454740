import * as Yup from 'yup'
import moment from 'moment'
import { useState } from 'react'
import { useFormik } from 'formik'

import { checkIsEmpty } from '../../../utils'
import { Form } from './agent.styled'
import { AgentAPI } from '../../../api'
import { MasterModal, Input, Alerts, ModalDel, InputSelect } from '../../../components'

const AgentModal = (props) => {
  const isEdit = props.edit
  const isDetail = props.detail
  const isDelete = props.delete
  const [multiClick, setMultClick] = useState(0)
  const [showAlert, setAlert] = useState({ visible: false, msg: '', success: false, type: '' })

  const _insertAgent = async (data) => {
    try {
      const response = await AgentAPI.InsertAgent(data)
      if (response && response.result) {
        setAlert({
          msg: 'Add data has been successful',
          success: true,
          visible: true,
          type: 'insert',
        })
      } else {
        setAlert({
          msg: 'Agent data already exists',
          success: false,
          visible: true,
          type: 'insert',
        })
      }
    } catch (err) {
      console.log(err)
      return err
    }
  }

  const _updateAgent = async (data) => {
    try {
      const response = await AgentAPI.UpdateAgent(data)
      if (response && response.result) {
        setAlert({
          msg: 'Update data has been successful',
          success: true,
          visible: true,
          type: 'update',
        })
      } else {
        setAlert({
          msg: 'Agent data already exists',
          success: false,
          visible: true,
          type: 'update',
        })
      }
    } catch (err) {
      console.log(err)
      return err
    }
  }

  const _deleteAgent = async () => {
    try {
      const response = await AgentAPI.DeleteAgent(props.data.Id)
      if (response && response.result) {
        props.setDeleted(false)
        setAlert({
          msg: 'Delete data has been successful',
          success: true,
          visible: true,
          type: 'delete',
        })
      } else {
        setAlert({ msg: 'Cannot delete data', success: false, visible: true, type: 'delete' })
      }
    } catch (err) {
      console.log(err)
      return err
    }
  }

  const _close = () => {
    setMultClick(0)
    setAlert({ ...showAlert, visible: false })
    if (showAlert.success) {
      props.setModal(false)
      FormInput.resetForm()
    }
  }

  const _deleteModal = () => (
    <div>
      <ModalDel
        submitDelete={_deleteAgent}
        deleteText='Are you sure to delete the data ?'
        toggleDialog={() => {
          FormInput.resetForm()
          props.setModal(false)
        }}
      />
    </div>
  )

  const FormInput = useFormik({
    enableReinitialize: true,
    initialValues: {
      Id: isEdit || isDetail ? props.data.Id : 0,
      Phone: isEdit || isDetail ? props.data.Phone : '',
      Address: isEdit || isDetail ? props.data.Address : '',
      Agent_Name: isEdit || isDetail ? props.data.Agent_Name : '',
      Is_Active: isEdit || isDetail ? props.data.Is_Active : true,
      Code: isEdit || isDetail ? props.data.Agent_Code : 'AGCXXXX',
      Mobile_Phone: isEdit || isDetail ? props.data.Mobile_Phone : '',
    },
    validationSchema: Yup.object({
      Id: Yup.number(),
      Code: Yup.string(),
      Phone: Yup.string().required('*Telephone*'),
      Address: Yup.string().required('*Address*'),
      Is_Active: Yup.boolean(),
      Mobile_Phone: Yup.string(),
      Agent_Name: Yup.string().required('Required*'),
    }),
    onSubmit: async (values, form) => {
      setMultClick(multiClick + 1)
      if (checkIsEmpty(values.Agent_Name)) {
        form.setValues({
          ...values,
          Agent_Name: '',
        })
      } else {
        let data = {}
        Object.keys(values)
          .filter(
            (val) =>
              val == 'Id' ||
              val == 'Phone' ||
              val == 'Address' ||
              val == 'Is_Active' ||
              val == 'Mobile_Phone' ||
              val == 'Agent_Name'
          )
          .forEach((vl) => {
            data[vl] = values[vl]
          })
        if (isEdit) {
          if (multiClick === 0) {
            _updateAgent(data)
          }
        } else {
          if (multiClick === 0) {
            _insertAgent(data)
          }
        }
      }
    },
  })

  return (
    <>
      {isDelete && _deleteModal()}
      {props.modal && (
        <MasterModal
          maxWidth='lg'
          width={35}
          add={!isDetail}
          title='Master Data'
          showModal={props.modal}
          handleClose={() => {
            FormInput.resetForm()
            props.setModal(false)
          }}
          subTitle={
            isEdit
              ? 'Edit Master Data Agent'
              : isDetail
                ? 'Detail Master Data Agent'
                : 'Add Master Data Agent'
          }
          handleSave={FormInput.handleSubmit}
        >
          <Form>
            <Input
              id='m_agt_code_txt'
              name='Code'
              form={FormInput}
              label='Agent Code'
              value={FormInput.values.Code}
              InputProps={{ readOnly: true }}
            />
            <Input
              id='m_agt_address_txt'
              rowsMax={5}
              name='Address'
              label='Address'
              form={FormInput}
              multiline={true}
              variant='outlined'
              style={{ marginTop: '-6.5px' }}
              InputProps={{ readOnly: isDetail }}
              value={FormInput.values.Address}
            />
            {(isEdit || isDetail) && (
              <Input
                id='m_agt_modby_txt'
                label='Modified By'
                InputProps={{ readOnly: true }}
                value={props.data.Modified_By}
              />
            )}

            {(isEdit || isDetail) && (
              <Input
                id='m_agt_modon_txt'
                label='Modified On'
                InputProps={{ readOnly: true }}
                value={moment(props.data.Modified_Date).format('DD/MM/YYYY HH:mm:ss')}
              />
            )}
          </Form>
          <Form>
            <Input
              id='agent_name_txt'
              form={FormInput}
              name='Agent_Name'
              label='Agent Name'
              value={FormInput.values.Agent_Name}
              InputProps={{ readOnly: isDetail }}
            />
            <Input
              id='m_agt_phone_txt'
              form={FormInput}
              name='Phone'
              label='Telephone'
              value={FormInput.values.Phone}
              InputProps={{ readOnly: isDetail }}
            />
            <Input
              id='m_agt_mphone_txt'
              form={FormInput}
              name='Mobile_Phone'
              label='Mobile Phone'
              value={FormInput.values.Mobile_Phone}
              InputProps={{ readOnly: isDetail }}
            />
            {(isEdit || isDetail) && (
              <InputSelect
                id='m_agt_status_txt'
                label='Status'
                name='Is_Active'
                options={[
                  { text: 'Enable', value: true },
                  { text: 'Disable', value: false },
                ]}
                optionText='text'
                optionValueKey='value'
                readOnly={!isEdit}
                form={FormInput}
              />
            )}
          </Form>
        </MasterModal>
      )}
      <Alerts
        type={showAlert.type}
        msg={showAlert.msg}
        close={() => _close()}
        open={showAlert.visible}
        success={showAlert.success}
      />
    </>
  )
}

export default AgentModal
