import styled from 'styled-components'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

export const Container1 = styled.div`
  width: 100%;
  padding: 50px 0;
`
export const TitleTable = styled.p`
  float: left;
  font-size: 24px;
`
export const ButtonAdd = styled.div`
  float: right;
  display: flex;
  font-size: 17px;
  margin-top: auto;
`
export const Container2 = styled.div`
  width: 100%;
  margin-top: 80px 0;
`
export const DivContent = styled.div`
  height: 78.8px;
`
export const BtnSave = styled.div`
  margin: 28px 10px 10px auto;
  margin-right: 60px;
  margin-top: 37px;
`
export const IconEdit = styled(EditIcon)`
  color: #71af00;
`
export const Img = styled.img`
  width: 100%;
  height: auto;
`
export const TitleDialog = styled.p`
  float: right;
  margin-left: 50px;
  margin-top: 30px;
  display: inline;
  font-size: 24px;
  font-weight: bold;
  color: #0082ad;
  text-transform: uppercase;
`
export const DialogImage = styled.div`
  width: 100%;
  height: 104px;
  background-color: #d5e9f1;
`
export const DivTitle = styled.div`
  float: left;
`
export const IconDialog = styled.img`
  float: left;
  width: 120px;
  height: 120px;
  margin-left: 70px;
  margin-top: -10px !important;
`
export const SureDelete = styled.div`
  text-align: center;
`
export const DivBtnDel = styled.div`
  margin-top: 30px;
  text-align: center;
`
export const RowIcon = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: center;
  justify-content: space-around;
`

export const BtnColOption = styled.div`
  cursor: pointer;
  font-size: 14px;
  justify-content: space-between;
  margin: 15px 50px 90px auto;
`
export const IconDel = styled(DeleteIcon)`
  color: black;
`
export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.height}px;
`
export const Form = styled.div`
  flex: 1;
  display: flex;
  padding: 10px 30px;
  flex-direction: column;
`
export const RowDetail = styled.td`
  cursor: pointer;
`
export const CloseButtonIcon = styled.div`
  cursor: pointer;
  margin-left: 58px;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  font-size: small;
`
export const ImageIconFile = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-left: 20px;
  cursor: pointer;
  margin-top:${(p) => (p.type === 'detail' ? '0px' : '-20px')};
`
export const ImageIconImage = styled.img`
  width: 20vh;
  height: 10vh;
  border-radius: 5px;
  margin-left: 20px;
  cursor: pointer;
  object-fit: cover;
  margin-top: ${(p) => (p.type === 'detail' ? '0px' : '0px')};
`
export const FileContainer = styled.div`
  margin-top: 5px;
  margin-right: 10px;
  display: ${(p) => (p.type === 'detail' ? '' : 'inline-block')};
  position: relative;
  text-align: ${(p) => (p.type === 'detail' ? 'center' : '')};
`
export const FileContainerImage = styled.div`
  margin-top: 5px;
  margin-right: 10px;
  display: ${(p) => (p.type === 'detail' ? '' : 'inline-block')};
  position: relative;
  text-align: ${(p) => (p.type === 'detail' ? 'center' : '')};
`
export const ActionCell = styled.div`
  cursor: pointer;
`
