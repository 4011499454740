import { useState, useEffect } from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import CreateIcon from '@material-ui/icons/Create'

import GridColumn from './column.json'
import { AgentAPI } from '../../../api'
import AgentModal from './agent.modal.container'
import { GenerateFilterTerm } from '../../../utils'
import { WrapperContent } from '../../../styles/global.styled'
import { Loading } from '../../../components/loading/loading.styled'
import { Card, MasterHeader, MasterGrid, CodeCell } from '../../../components'

import { Colors } from '../../../styles'
import { LoadingContainer, ActionContainer, ActionCell } from './agent.styled'

const Agent = () => {
  const height = window.innerHeight - 190 > 455 ? window.innerHeight - 190 : 455
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 20,
    filter: [],
    sortable: true,
    filterable: true,
    filterString: '[]',
  })
  const [modal, setModal] = useState(false)
  const [detail, setDetail] = useState(false)
  const [edited, setEdited] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataAgent, setDataAgent] = useState([])
  const [dataItem, setDataItem] = useState(null)
  const [totalAgent, setTotalAgent] = useState(0)
  const [pending, setPending] = useState(false)

  const _getData = async (loading, { skip, take, filterString }) => {
    setLoading(loading)
    try {
      setPending(true)
      const { data, dataCount } = await AgentAPI.GetAllMasterDataAgent(skip, take, filterString)
      setDataAgent(data)
      setTotalAgent(dataCount)
      setPending(false)
    } catch (error) {
      setPending(false)
      console.log(error)
    }
    setLoading(false)
  }

  const _onDataStateChange = ({ data }) => {
    GenerateFilterTerm(data.filter, GridColumn)
    setDataState({
      ...dataState,
      ...data,
      filter: data.filter,
      filterString: GenerateFilterTerm(data.filter, GridColumn),
    })

    _getData(false, {
      ...dataState,
      ...data,
      filterString: GenerateFilterTerm(data.filter, GridColumn),
    })
  }

  const _onPageChange = (event) => {
    setDataState({
      ...dataState,
      skip: event.page.skip,
      take: event.page.take,
    })

    _getData(false, {
      skip: event.page.skip,
      take: event.page.take,
      filterString: dataState.filterString,
    })
  }

  const _codeCell = ({ dataItem }) => (
    <CodeCell
      id='m-detail-btn'
      onClick={() => {
        setDataItem(dataItem)
        setModal(!modal)
        setDetail(!detail)
      }}
    >
      {dataItem.Agent_Code}
    </CodeCell>
  )

  useEffect(() => {
    _getData(true, dataState)
  }, [])

  useEffect(() => {
    _getData(false, dataState)
  }, [modal, deleted])

  const _header = () => {
    return (
      <MasterHeader
        title='MASTER DATA AGENT'
        buttonTitle='Add New'
        onClick={() => {
          setModal(!modal)
        }}
      />
    )
  }

  const _actionCell = ({ colSpan, dataItem }) => (
    <td>
      <ActionContainer
        id='m_agt_action_btn'
        onClick={() => setDataItem(dataItem)}
        colSpan={colSpan}
      >
        <ActionCell
          id='m-edit-btn'
          onClick={() => {
            setModal(!modal)
            setEdited(!edited)
          }}
        >
          <CreateIcon htmlColor={Colors.green} />
        </ActionCell>
        <ActionCell
          id='m-delete-btn'
          onClick={() => {
            setDeleted(!deleted)
          }}
        >
          <DeleteIcon htmlColor={Colors.black} />
        </ActionCell>
      </ActionContainer>
    </td>
  )

  const _grid = () => {
    return (
      <MasterGrid
        id='m_agt_grid'
        height={height}
        data={dataAgent}
        total={totalAgent}
        codeCell={_codeCell}
        dataState={dataState}
        gridColumn={GridColumn}
        actionCell={_actionCell}
        pageSizes={[20, 50, 100]}
        onPageChange={(e) => _onPageChange(e)}
        onDataStateChange={(e) => _onDataStateChange(e)}
        pending={pending}
      />
    )
  }

  const _clearModal = () => {
    setModal(false)
    setDetail(false)
    setEdited(false)
    setDeleted(false)
    setDataItem(null)
  }

  return (
    <WrapperContent>
      <Card>
        {_header()}
        {loading ? (
          <LoadingContainer height={height}>
            <Loading />
          </LoadingContainer>
        ) : (
          _grid()
        )}
        <AgentModal
          id='m_agt_mdl'
          modal={modal}
          edit={edited}
          detail={detail}
          data={dataItem}
          delete={deleted}
          setDeleted={setDeleted}
          setModal={() => _clearModal()}
        />
      </Card>
    </WrapperContent>
  )
}

export default Agent
